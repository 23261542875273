import React from 'react';

import { Outlet } from 'react-router';

import {
  AuthenticatedSellerOuterTemplate,
  NonAuthenticatedSellerOuterTemplate,
  SellerPayLinksTemplate,
} from './templates';
import {
  NewRoutesWrapperArrayType,
  NewRoutesWrapperType,
  SafeNavigate,
} from './utils';
import { Dashboard } from '../Seller/Dashboard/Dashboard';
import { IntegrationsDashboard } from '../Seller/Integrations/components/IntegrationsDashboard/IntegrationsDashboard';
import { WebhooksPage } from '../Seller/Integrations/components/Webhooks/Webhooks';
import { CredentialsPage } from '../Seller/Integrations/CredentialsPage/CredentialsPage';
import { OneTimeLinksPage } from '../Seller/PayLinks/OneTimeLinksPage/OneTimeLinksPage';
import { PackageLinkPage } from '../Seller/PayLinks/PackageLinkPage/PackageLinkPage';
import { ReusablePaylinkPage } from '../Seller/PayLinks/ReusablePaylinkPage/ReusablePaylinkPage';
import { Account } from '../Seller/Xero/Account/Account';
import { Authorisation } from '../Seller/Xero/Authorisation/Authorisation';
import { Connect } from '../Seller/Xero/Connect/Connect';
import { InvoiceBrandingTheme } from '../Seller/Xero/InvoiceBrandingTheme/InvoiceBrandingTheme';
import { PaymentAccount } from '../Seller/Xero/PaymentAccount/PaymentAccount';
import { SuccessfullyLinked } from '../Seller/Xero/SuccessfullyLinked/SuccessfullyLinked';
import { EvaluateXeroStep } from '../Seller/Xero/Xero';

export const redirectRoutes = [
  {
    element: <Outlet />,
    children: [
      {
        path: 'contract/update',
        element: <SafeNavigate to="/pay/pay-links/universal" />,
      },
      {
        path: 'onboarding/*',
        element: <SafeNavigate to="/pay/activation" />,
      },
      {
        path: 'pay-links',
        element: <SafeNavigate to="/pay/pay-links/universal" />,
      },
    ],
  },
];

const MANAGE_PAGES = [
  {
    path: 'manage-account',
    lazy: async () => {
      const { ManageAccountPage } = await import('./seller.pages');
      return {
        element: <ManageAccountPage />,
      };
    },
  },
  {
    path: 'manage-users',
    lazy: async () => {
      const { ManageUsersPage } = await import('./seller.pages');
      return {
        element: <ManageUsersPage />,
      };
    },
    accessibleByGroups: ['customers', 'iwocapay_seller_admin'],
  },
] as const satisfies NewRoutesWrapperType['paths'];

const REWARDS = [
  {
    path: 'rewards',
    lazy: async () => {
      const { RewardsPage } = await import('./seller.pages');
      return {
        element: <RewardsPage />,
      };
    },
  },
] as const satisfies NewRoutesWrapperType['paths'];

const ACTIVATION = [
  {
    path: 'activation',
    lazy: async () => {
      const { AccountActivationPage } = await import('./seller.pages');
      return {
        element: <AccountActivationPage />,
      };
    },
  },
] as const satisfies NewRoutesWrapperType['paths'];

const NOT_ONBOARDED = [
  {
    path: 'seller/not-onboarded',
    lazy: async () => {
      const { NotOnboardedPage } = await import('./seller.pages');
      return {
        element: <NotOnboardedPage />,
      };
    },
  },
] as const satisfies NewRoutesWrapperType['paths'];

const SPENDING_LIMIT = [
  {
    path: 'spending-limits',
    lazy: async () => {
      const { SpendingLimitPage } = await import('./seller.pages');
      return {
        element: <SpendingLimitPage />,
      };
    },
  },
] as const satisfies NewRoutesWrapperType['paths'];

const DASHBOARD = [
  {
    path: 'dashboard',
    element: <Dashboard />,
  },
] as const satisfies NewRoutesWrapperType['paths'];

const PAYMENTS = [
  {
    path: 'payments',
    lazy: async () => {
      const { PaymentsPage } = await import('./seller.pages');
      return {
        element: <PaymentsPage />,
      };
    },
  },
] as const satisfies NewRoutesWrapperType['paths'];

const XERO = [
  {
    path: 'xero/connect',
    element: <Connect />,
  },
  {
    path: 'xero/authorisation',
    element: <Authorisation />,
  },
  {
    path: 'xero/payment-account',
    element: <PaymentAccount />,
  },
  {
    path: 'xero/invoice-branding-theme',
    element: <InvoiceBrandingTheme />,
  },
  {
    path: 'xero/account',
    element: <Account />,
  },
  {
    path: 'xero/success',
    element: <SuccessfullyLinked />,
  },
  {
    path: 'xero/introduction', // we rely on this path to redirect automatically
    element: <></>,
  },
] as const satisfies NewRoutesWrapperType['paths'];

const INTEGRATIONS = [
  {
    path: 'integrations/:integrationParam',
    element: <IntegrationsDashboard />,
    accessibleByGroups: [
      'customers',
      'iwocapay_seller_admin',
      'iwocapay_seller_editor',
    ],
  },
  {
    path: 'integrations',
    element: <IntegrationsDashboard />,
    accessibleByGroups: [
      'customers',
      'iwocapay_seller_admin',
      'iwocapay_seller_editor',
    ],
  },
  {
    path: 'integrations/credentials',
    element: <CredentialsPage />,
    accessibleByGroups: [
      'customers',
      'iwocapay_seller_admin',
      'iwocapay_seller_editor',
    ],
  },
  {
    path: 'integrations/webhooks',
    element: <WebhooksPage />,
    accessibleByGroups: [
      'customers',
      'iwocapay_seller_admin',
      'iwocapay_seller_editor',
    ],
  },
] as const satisfies NewRoutesWrapperType['paths'];

const PAY_LINK = [
  {
    path: 'pay-links/universal',
    element: (
      <SellerPayLinksTemplate>
        <ReusablePaylinkPage />
      </SellerPayLinksTemplate>
    ),
  },
  {
    path: 'pay-links/package',
    element: (
      <SellerPayLinksTemplate>
        <PackageLinkPage />
      </SellerPayLinksTemplate>
    ),
  },
  {
    path: 'pay-links/custom',
    element: (
      <SellerPayLinksTemplate>
        <OneTimeLinksPage />
      </SellerPayLinksTemplate>
    ),
  },
] as const satisfies NewRoutesWrapperType['paths'];

// Below: Path wrappers
const AUTHENTICATED_PAGES = {
  wrapper: (
    <AuthenticatedSellerOuterTemplate>
      <Outlet />
    </AuthenticatedSellerOuterTemplate>
  ),
  paths: [
    ...MANAGE_PAGES,
    ...REWARDS,
    ...SPENDING_LIMIT,
    ...DASHBOARD,
    ...PAYMENTS,
    ...INTEGRATIONS,
    ...PAY_LINK,
  ],
} as const satisfies NewRoutesWrapperType;

const UNAUTHENTICATED_PAGES = {
  wrapper: (
    <NonAuthenticatedSellerOuterTemplate>
      <Outlet />
    </NonAuthenticatedSellerOuterTemplate>
  ),
  paths: [...ACTIVATION, ...NOT_ONBOARDED],
} as const satisfies NewRoutesWrapperType;

const XERO_PAGES = {
  wrapper: (
    <AuthenticatedSellerOuterTemplate>
      <EvaluateXeroStep>
        <Outlet />
      </EvaluateXeroStep>
    </AuthenticatedSellerOuterTemplate>
  ),
  paths: XERO,
} as const satisfies NewRoutesWrapperType;

export const SELLER_ROUTES = [
  XERO_PAGES,
  UNAUTHENTICATED_PAGES,
  AUTHENTICATED_PAGES,
] as const satisfies NewRoutesWrapperArrayType;
