import {
  GetDocumentUploadResponse,
  GetDrawDownRepaymentScheduleIwocapayResponse,
  GetFundingRequirementsIwocapayResponse,
  GetLoanDocumentsRequiredIwocapayResponse,
  GetOnfidoCheckResponse,
  GetStateByStateKeyResponse,
  GetXeroConnectionsResponse,
  PostDocumentUploadResponse,
  PostIwocapayPaymentLaterRequestBody,
  PostOnfidoStartResponse,
  PostStateRequestBody,
  PutStateRequestBody,
} from '@iwoca/lapi-client/edge';

import { LapiError } from './LapiError';
import {
  lendingApiFetchJson,
  postLendingApiJson,
  postLendingApiMultipartData,
  putLendingApiJson,
} from '../../Pages/lendingApiFetch';
import { GetPaymentAccountsResponse } from '../../Seller/Xero/PaymentAccount/PaymenAccount.types';
import { getRuntimeEnvironment } from '../../utils/getRuntimeEnvironment';
import { RecursiveNonNullable } from '../../utils/typeUtils';

export type GetCustomerStateResponse = GetStateByStateKeyResponse & {
  data: {
    ui?: {
      iwocapay_paylink_id?: string;
      agreeSoleTraderPilotDeclaration?: string;
    };
  };
};

export type CustomerStateTitle = NonNullable<
  NonNullable<GetCustomerStateResponse['data']['application']>['people']
>[0]['title'];

export type CustomerStateCompanyType =
  RecursiveNonNullable<GetCustomerStateResponse>['data']['application']['company']['type'];

export type CustomerState = GetCustomerStateResponse['data'];

const PRODUCTION_SELLER_REGISTRATION_TOKEN =
  'e9efe6f1d9896091450e4c7ef47a65d148296abd';
const STAGING_SELLER_REGISTRATION_TOKEN =
  '11fdcce04f91c0d8d1d16f82ba2a34c10f8554ec';

export const fetchPostCustomerState = async ({
  data,
}: {
  data: PostStateRequestBody;
}) => {
  const API_AUTH_TOKEN =
    getRuntimeEnvironment() === 'production'
      ? PRODUCTION_SELLER_REGISTRATION_TOKEN
      : STAGING_SELLER_REGISTRATION_TOKEN;

  const res = await postLendingApiJson({
    url: `/api/lending/edge/state/`,
    body: data,
    headers: {
      authorization: `Bearer ${API_AUTH_TOKEN}`,
    },
  });

  if (!res.ok) {
    throw new LapiError(res.status, await res.text());
  }

  return res.json();
};

export const fetchPutCustomerState = async ({
  stateKey,
  data,
}: {
  stateKey: string;
  data: PutStateRequestBody;
}) => {
  const res = await putLendingApiJson({
    url: `/api/lending/edge/state/${stateKey}/`,
    body: data,
  });

  if (!res.ok) {
    throw new LapiError(res.status, await res.text());
  }

  return res.json();
};

export type RequestedDuration = NonNullable<
  NonNullable<
    NonNullable<PostIwocapayPaymentLaterRequestBody>['data']
  >['requested_duration']
>;

export const fetchGetXeroConnections = async (
  stateKey: string,
): Promise<GetXeroConnectionsResponse> => {
  const res = await lendingApiFetchJson(
    `/api/lending/edge/xero/connections/iwocapay_seller/${stateKey}/`,
  );

  if (!res.ok) {
    throw new LapiError(res.status, await res.text());
  }

  return res.json();
};

export async function fetchGetXeroPaymentAccounts(
  stateKey?: string,
): Promise<GetPaymentAccountsResponse> {
  const res = await lendingApiFetchJson(
    `/api/lending/edge/xero/accounts/iwocapay_seller/${stateKey}/?account_type=payment`,
  );

  if (!res.ok) {
    throw new LapiError(res.status, await res.text());
  }

  return res.json();
}

export const fetchGetXeroAccountsIwocapaySeller = async (
  stateKey: string,
): Promise<GetPaymentAccountsResponse> => {
  const res = await lendingApiFetchJson(
    `/api/lending/edge/xero/accounts/iwocapay_seller/${stateKey}/?account_type=payment`,
  );

  if (!res.ok) {
    throw new LapiError(res.status, await res.text());
  }

  return res.json();
};

export const fetchPostXeroIwocaPaySellerAccount = async (
  stateKey: string,
  tenantId: string,
) => {
  const res = await postLendingApiJson({
    url: `/api/lending/edge/xero/iwocapay_seller/account/${stateKey}/`,
    body: {
      data: {
        xero_tenantid: tenantId,
      },
    },
  });

  if (!res.ok) {
    throw new LapiError(res.status, await res.text());
  }
};

export const fetchPostConnectIwocaPaySeller = async ({
  stateKey,
  code,
  state,
}: {
  stateKey: string;
  code: string;
  state: string;
}) => {
  const res = await postLendingApiJson({
    url: `/api/lending/edge/xero/connect/iwocapay_seller/${stateKey}/`,
    body: {
      data: {
        code,
        state,
      },
    },
  });

  if (!res.ok) {
    throw new LapiError(res.status, await res.text());
  }
};

export const fetchPostOnfidoSmsResponse = async ({
  stateKey,
  personUid,
  phoneUid,
}: {
  stateKey: string | null | undefined;
  personUid: string | undefined;
  phoneUid: string | undefined;
}): Promise<PostOnfidoStartResponse> => {
  const res = await postLendingApiJson({
    url: `/api/lending/edge/onfido/start/${stateKey}/`,
    body: {
      data: {
        person_uid: personUid,
        phone_uid: phoneUid,
      },
    },
  });

  if (!res.ok) {
    throw new LapiError(res.status, await res.text());
  }

  return res.json();
};

export type GetOnfidoCheckPayload = {
  data: {
    result: number;
    status: number;
  };
};

export const fetchGetOnfidoCheckStatus = async ({
  stateKey,
  personUid,
}: {
  stateKey: string | null | undefined;
  personUid: string | undefined;
}): Promise<GetOnfidoCheckResponse> => {
  const res = await lendingApiFetchJson(
    `/api/lending/edge/onfido/check/${stateKey}/${personUid}/`,
  );

  if (!res.ok) {
    throw new LapiError(res.status, await res.text());
  }

  return res.json();
};

export const fetchGetDocumentUpload = async ({
  stateKey,
}: {
  stateKey: string;
}): Promise<GetDocumentUploadResponse> => {
  const res = await lendingApiFetchJson(
    `/api/lending/edge/document_upload/${stateKey}/`,
  );

  if (!res.ok) {
    throw new LapiError(res.status, await res.text());
  }

  return res.json();
};

type DocumentUploadBodyDocumentType =
  PostDocumentUploadResponse['data']['document_type'];

export type DocumentUploadBody = {
  document: File;
  document_type: DocumentUploadBodyDocumentType;
};

export const fetchPostDocumentUpload = async ({
  stateKey,
  body,
}: {
  stateKey: string;
  body: DocumentUploadBody;
}): Promise<PostDocumentUploadResponse> => {
  const res = await postLendingApiMultipartData({
    url: `/api/lending/edge/document_upload/${stateKey}/`,
    body,
  });

  if (!res.ok) {
    throw new LapiError(res.status, await res.text());
  }

  return res.json();
};

/** Django hasn't generated the correct schema -> Missing query params */
export async function fetchGetDrawDownRepaymentScheduleIwocapay({
  stateKey,
  offerId,
  query: { payLinkId },
}: {
  stateKey: string;
  offerId: string;
  query: { payLinkId: string };
}): Promise<GetDrawDownRepaymentScheduleIwocapayResponse> {
  const res = await lendingApiFetchJson(
    `/api/lending/edge/draw_down_repayment_schedule/${stateKey}/iwocapay/${offerId}/?pay_link_id=${payLinkId}`,
  );

  if (!res.ok) {
    throw new LapiError(res.status, await res.text());
  }

  return res.json();
}

/** Django hasn't generated the correct schema -> Missing query params */
export async function fetchGetLoanDocumentsRequiredIwocapay({
  stateKey,
  offerId,
  query: { payLinkId },
}: {
  stateKey: string;
  offerId: string;
  query: {
    payLinkId: string;
  };
}): Promise<GetLoanDocumentsRequiredIwocapayResponse> {
  const res = await lendingApiFetchJson(
    `/api/lending/edge/loan_documents/${stateKey}/required/iwocapay/${offerId}/?pay_link_id=${payLinkId}`,
  );

  if (!res.ok) {
    throw new LapiError(res.status, await res.text());
  }

  return res.json();
}

export type CustomerRoles = NonNullable<
  NonNullable<
    NonNullable<GetCustomerStateResponse['data']['application']>['people']
  >[0]['roles']
>[0];

export type TGetFundingRequirementsResponse =
  GetFundingRequirementsIwocapayResponse;

export const fetchGetFundingRequirements = async ({
  stateKey,
  offerId,
  query,
}: {
  stateKey: string;
  offerId: string;
  query?: {
    payLinkId?: string;
  };
}): Promise<TGetFundingRequirementsResponse> => {
  const searchParams = new URLSearchParams();
  if (query?.payLinkId) searchParams.append('pay_link_id', query.payLinkId);

  const res = await lendingApiFetchJson(
    `/api/lending/edge/funding_requirements/${stateKey}/iwocapay/${offerId}/${
      Array.from(searchParams).length > 0 ? `?${searchParams}` : ''
    }`,
  );

  if (!res.ok) {
    throw new LapiError(res.status, await res.text());
  }

  return res.json();
};
