import { useParams } from 'react-router';

import { BenefitItem } from './components/BenefitItem/BenefitItem';
import { EmailDetails } from './components/EmailDetails/EmailDetails';
import styles from './SpendingLimitLanding.module.css';
import { useGetSellerByHandle } from '../../api/lending/lapiHooks';
import { LoadingScreen } from '../../components/LoadingScreen/LoadingScreen';
import IwocaPayIcon from '../../components/svg/IwocaPayIcon.svg?react';
import { GenericError } from '../../Pages/FailurePages/GenericError';
import { HelpBox } from '../components/HelpBox/HelpBox';
import { useTrackLandingPageView } from '../hooks/useTrackLandingPageView';
import { useRedirectToMyAccount } from '../Signup/useRedirectToAccountPages';

export const SpendingLimitLanding = () => {
  const { sellerHandle } = useParams();
  const { sellerError, seller, loadingSeller } =
    useGetSellerByHandle(sellerHandle);

  useTrackLandingPageView({
    payLinkType: 'spending_limit',
    visibleOptions: ['THREE_MONTHS'],
    productPricing: seller?.product_pricing!,
    sellerId: seller?.seller_id,
    loadingApis: loadingSeller,
  });

  useRedirectToMyAccount();

  if (sellerError) {
    return <GenericError errorText={sellerError} />;
  }
  if (!seller) {
    return <LoadingScreen />;
  }

  return (
    <div className={styles.wrap}>
      <div className={styles.content}>
        <div className={styles.createSpendingLimitContainer}>
          <IwocaPayIcon className={styles.iwocaPayLogo} />
          <div className={styles.heading}>Create your account today</div>
          <ul className={styles.benefits}>
            <BenefitItem>Get up to £30K to spend in minutes</BenefitItem>
            <BenefitItem>Split into equal monthly payments</BenefitItem>
            <BenefitItem>Spread over 3 or 12 months</BenefitItem>
          </ul>
          <EmailDetails />
        </div>
        <HelpBox />
      </div>
    </div>
  );
};
