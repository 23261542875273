import {
  fetchPostLoanDocumentsSigned,
  GetLoanDocumentsRequiredIwocapayResponse,
  PostLoanDocumentsSignedRequestBody,
} from '@iwoca/lapi-client/edge';
import { useMutation, useQuery } from '@tanstack/react-query';

import { useGetLoanDocumentsRequiredIwocapay } from '../../../api/lending/lapiHooks';
import { useStateKey } from '../../../hooks/useStateKey.hook';
import {
  lendingAPIFetchHTML,
  lendingAPIFetchPdf,
} from '../../../Pages/lendingApiFetch';
import { RecursiveNonNullable } from '../../../utils/typeUtils';

type TLoanDocumentType =
  RecursiveNonNullable<GetLoanDocumentsRequiredIwocapayResponse>['data']['required_loan_documents'][number]['type'];
export function useLoanDocument(loanDocumentType: TLoanDocumentType) {
  const { stateKey } = useStateKey();
  const { loanDocumentsRequiredIwocapay, fetchLoanDocumentsRequiredIwocapay } =
    useGetLoanDocumentsRequiredIwocapay();

  const loanDocument =
    loanDocumentsRequiredIwocapay?.required_loan_documents.find(
      (required_loan_document) =>
        required_loan_document.type === loanDocumentType,
    );

  const isLoanDocumentSigned = Boolean(loanDocument?.agreed);
  const unsignedUrl = loanDocument?.downloads?.unsigned;
  const signedUrl = loanDocument?.downloads?.signed;
  const downloadUrl = isLoanDocumentSigned ? signedUrl : unsignedUrl;

  const { data: loanDocumentContent, isLoading: isLoadingLoanDocumentContent } =
    useQuery({
      queryKey: ['document', loanDocumentType],
      queryFn: () => lendingAPIFetchHTML(downloadUrl!),
      enabled: Boolean(downloadUrl),
    });

  const { mutateAsync, isPending: isSigningLoanDocument } = useMutation({
    mutationFn: ({
      stateKey,
      documentId,
    }: {
      stateKey: string;
      documentId: string;
    }) => {
      const agreeDocumentBody = buildBodyAgreeDocument(documentId);

      return fetchPostLoanDocumentsSigned({
        stateKey: stateKey,
        body: agreeDocumentBody,
      });
    },
    onSuccess: async () => {
      await fetchLoanDocumentsRequiredIwocapay();
    },
  });

  const downloadLoanDocument = async () => {
    if (!downloadUrl) {
      throw new Error(
        `Missing download URL for document with id: ${loanDocument?.document_id}`,
      );
    }

    await lendingAPIFetchPdf(downloadUrl);
  };

  const canSignLoanDocument = Boolean(loanDocument?.document_id);

  const signLoanDocument = () => {
    if (!stateKey) {
      throw new Error('No statekey provided');
    }

    if (!loanDocument?.document_id) {
      throw new Error('No document id provided');
    }

    return mutateAsync({
      stateKey: stateKey,
      documentId: loanDocument.document_id,
    });
  };

  const isLoadingLoanDocument =
    isLoadingLoanDocumentContent || !loanDocumentsRequiredIwocapay;

  return {
    loanDocument,
    isLoadingLoanDocument,
    loanDocumentContent,
    downloadLoanDocument,

    canSignLoanDocument,
    signLoanDocument,
    isLoanDocumentSigned,
    isSigningLoanDocument,
  };
}

function buildBodyAgreeDocument(
  uid: string,
): PostLoanDocumentsSignedRequestBody {
  const dateSigned = new Date().toISOString().substring(0, 10);

  return {
    data: {
      document_id: uid,
      date_signed: dateSigned,
    },
  };
}
