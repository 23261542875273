import { GetEmailAvailabilityResponse } from '@iwoca/lapi-client/edge';
import { saveAs } from 'file-saver';
import Cookies from 'js-cookie';

import { DocumentUploadBody } from '../api/lending/edge';

const BUYER_REGISTRATION_API_TOKEN =
  'Token iwoca-2ay95jmavm6gw12hf20iq97oc1bb5okti3c';

export const lendingApiFetchJson = (url: string, options = {}, headers = {}) =>
  // eslint-disable-next-line iwoca/prefer-lapi-client
  fetch(url, {
    credentials: 'same-origin',
    ...options,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      authorization: BUYER_REGISTRATION_API_TOKEN,
      ...headers,
    },
  });

export async function lendingAPIFetchPdf(url: string) {
  const rewrittenURL = url.replace('https://stage.iwoca-dev.co.uk', '');

  // eslint-disable-next-line iwoca/prefer-lapi-client
  const response = await fetch(rewrittenURL, {
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      accept: 'application/pdf',
      authorization: BUYER_REGISTRATION_API_TOKEN,
    },
  });

  return downloadAsAttachment(response);
}

function getFilenameFromResponse(response: Response) {
  const contentDisposition = response.headers.get('Content-Disposition');
  if (contentDisposition === null) {
    throw new Error('No filename found');
  }

  return /filename="(.+)"/.exec(contentDisposition)?.[1];
}

export async function downloadAsAttachment(response: Response) {
  const filename = getFilenameFromResponse(response);
  const responseBlob = await response.blob();
  const fileBlob = new Blob([responseBlob], { type: 'application/pdf' });

  return saveAs(fileBlob, filename);
}

export async function lendingAPIFetchHTML(url: string) {
  const rewrittenURL = url.replace('https://stage.iwoca-dev.co.uk', '');

  // eslint-disable-next-line iwoca/prefer-lapi-client
  const response = await fetch(rewrittenURL, {
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      accept: 'text/html',
      authorization: BUYER_REGISTRATION_API_TOKEN,
    },
  });

  return response.text();
}

export function postLendingApiJson({
  url,
  body,
  headers,
}: {
  url: string;
  body?: object;
  headers?: HeadersInit;
}) {
  // eslint-disable-next-line iwoca/prefer-lapi-client
  return fetch(url, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'x-csrftoken': Cookies.get('csrftoken') as string,
      ...headers,
    },
    ...(body && { body: JSON.stringify(body) }),
  });
}

export function putLendingApiJson<T>({ url, body }: { url: string; body?: T }) {
  // eslint-disable-next-line iwoca/prefer-lapi-client
  return fetch(url, {
    method: 'PUT',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'x-csrftoken': Cookies.get('csrftoken') as string,
    },
    ...(body && { body: JSON.stringify(body) }),
  });
}

export function deleteLendingApi(url: string) {
  // eslint-disable-next-line iwoca/prefer-lapi-client
  return fetch(url, {
    method: 'DELETE',
    credentials: 'same-origin',
    headers: {
      'x-csrftoken': Cookies.get('csrftoken') as string,
    },
  });
}

export const getEmailAvailability = async (
  emailAddress: string,
): Promise<boolean> => {
  const encodedEmailAddress = encodeURIComponent(emailAddress);
  const apiResponse = await lendingApiFetchJson(
    `/api/lending/edge/email_availability/?email=${encodedEmailAddress}`,
  );
  const responsePayload: GetEmailAvailabilityResponse =
    await apiResponse.json();

  return responsePayload.data.available;
};

export function postLendingApiMultipartData({
  url,
  body,
}: {
  url: string;
  body: DocumentUploadBody;
}) {
  const formData = new FormData();

  for (const [key, value] of Object.entries(body)) {
    if (value === undefined) continue;

    formData.append(key, value);
  }

  // eslint-disable-next-line iwoca/prefer-lapi-client
  return fetch(url, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'x-csrftoken': Cookies.get('csrftoken') as string,
      Accept: 'application/json',
    },
    body: formData,
  });
}
