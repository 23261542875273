import { useContext } from 'react';

import { tracking } from '@iwoca/frontend-tracking-library';
import { Button, Icon, Input } from '@iwoca/orion';
import { useLocation } from 'react-router';

import { ActivationContext } from '../../activation/Activation';
import { LockedButton } from '../LockedButton/LockedButton';
import { copy } from '../util/copy';

export const PayMeLink = ({ payMeUrl }: { payMeUrl: string }) => {
  const { isOnboarded, isLoading } = useContext(ActivationContext);
  const url = isOnboarded ? payMeUrl : 'https://iwocaPay.me/ExampleCompanyLtd';

  const currentUrl = useLocation().pathname;
  if (isLoading) return null;
  if (!isOnboarded) return <LockedButton />;

  return (
    <div className="grid grid-cols-[1fr,max-content] gap-xs">
      <Input readOnly={true} value={url} />
      <Button
        className="flex gap-xs"
        type="button"
        onClick={async () => {
          await copy(payMeUrl);
          tracking.ampli.payLinkCopied({ url: currentUrl });
        }}
        title="Copy iwocaPay link"
      >
        <span className="sm:hidden">Copy link</span>
        <Icon icon="copyOutline" />
      </Button>
    </div>
  );
};
