import cn from 'classnames';
import { useParams } from 'react-router';

import styles from './IntegrationButton.module.css';
import { LinkWithQuery } from '../../../../components/LinkWithQuery/LinkWithQuery';
import timerSVG from '../../assets/timer.svg';
import { useGetIntegrationStatus } from '../../hooks/useGetIntegrationStatus.hook';
import { Integration } from '../../utils/integrations.types';

export function IntegrationButton({
  integration,
  handleClick,
}: {
  integration: Integration;
  handleClick: () => void;
}) {
  const { integrationStatus } = useGetIntegrationStatus({
    integrationId: integration.id,
  });
  const { integrationParam } = useParams();

  if (integration.disabled) {
    return (
      <div className={styles.disabledButton}>
        <div className={styles.faded}>
          <div className={styles.integrationType}>
            {integration.company_type}
          </div>
          <div className={styles.integrationName}>
            {integration.company_name}
          </div>
        </div>
        <div className={styles.comingSoon}>
          <img src={timerSVG} alt="Timer Icon" className={styles.timerIcon} />
          We're working on it!
        </div>
      </div>
    );
  }
  return (
    <LinkWithQuery
      className={styles.linkWrapper}
      to={`/pay/integrations/${integration.id}`}
      onClick={() => handleClick()}
    >
      <div
        className={cn(styles.integrationButton, {
          [styles.active]: integrationParam === integration.id,
        })}
      >
        <div className={styles.integrationButtonInnerContainer}>
          <img
            src={integration.company_logo_svg}
            alt="Logo"
            className="m-auto mr-l aspect-square w-2xl"
          />
          <div className={styles.integrationTitle}>
            <div className={styles.integrationType}>
              {integration.company_type}
            </div>
            <div className={styles.integrationName}>
              {integration.company_name}
            </div>
          </div>
        </div>
        {integrationStatus === 'CONNECTED' && (
          <div className={styles.isConnected}>Connected</div>
        )}
      </div>
    </LinkWithQuery>
  );
}
