import { GetAccountsResponse } from '@iwoca/lapi-client/edge';

export function getIwocaPayFormalOffers(data: GetAccountsResponse | null) {
  const accounts = data?.data?.accounts;

  if (!accounts) return null;

  return accounts[0].formal_offers?.filter((offer) => {
    if (!offer.is_valid) return false;
    return offer.product_type === 'iwocapay';
  });
}
