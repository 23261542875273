import { AlertBox, Button } from '@iwoca/orion';
import cn from 'classnames';
import { useParams } from 'react-router';

import styles from './ShopifyBody.module.css';
import externalLinkSVG from '../../../../assets/externalLink.svg';
import { useGetCustomerEmail } from '../../../../hooks/useGetCustomerEmail';
import rocket from '../../assets/rocket.svg';
import tickSVG from '../../assets/tick.svg';
import { useGetIntegrationStatus } from '../../hooks/useGetIntegrationStatus.hook';
import { Shopify } from '../../Integrations';
import { integrationNameToPlatformNameMap } from '../../utils/integrationNameToPlatformNameMap';
import { postInterestToHubspot } from '../../utils/postInterestToHubspot';
import bodyStyles from '../IntegrationBody.module.css';
import { IntegrationsTitle } from '../IntegrationsTitle/IntegrationsTitle';

export function ShopifyBody() {
  const { integrationParam } = useParams();
  const { integrationStatus, loadingIwocapaySellerConnection } =
    useGetIntegrationStatus({
      integrationId: Shopify.id,
    });
  const email = useGetCustomerEmail();

  const isConnected = integrationStatus === 'CONNECTED';

  const buttonText = isConnected ? 'Manage on Shopify' : 'Install plugin';

  const handleButtonClick = async () => {
    const buttonLink =
      'https://accounts.shopify.com/store-login?redirect=%2Fadmin%2Fsettings%2Fpayments%2Falternative-providers%2F33619969';

    const hubspotPlaformName = integrationNameToPlatformNameMap['shopify'];

    if (hubspotPlaformName && !isConnected) {
      await postInterestToHubspot({
        email: email!,
        platform: hubspotPlaformName,
      });
    }
    window.location.href = buttonLink;
  };

  if (integrationParam !== Shopify.id) {
    return null;
  }
  if (loadingIwocapaySellerConnection) {
    return null;
  }
  return (
    <div className={bodyStyles.container}>
      <div className={bodyStyles.header}>
        <IntegrationsTitle integration={Shopify} />
        <div
          className={cn(bodyStyles.connectButtonWrapper, {
            [bodyStyles.connectButtonWrapperConnected]: isConnected,
          })}
        >
          {isConnected && (
            <div className={bodyStyles.isConnected}>
              <img
                src={tickSVG}
                alt="Tick Icon"
                className={bodyStyles.tickSVG}
              />
              Connected
            </div>
          )}
          <Button
            variant={isConnected ? 'secondary-alt' : 'primary'}
            onClick={handleButtonClick}
            className={cn({
              [styles.connectButton]: !isConnected,
            })}
          >
            {buttonText}
          </Button>
        </div>
      </div>
      <div className={bodyStyles.body}>
        <div>
          <p>
            {isConnected ? (
              <>
                <AlertBox variant="connect" className={styles.alertBox}>
                  <img src={rocket} alt="rocket" />
                  <div>
                    <b>Supercharge your conversion - </b>
                    We’ve made launching iwocaPay on Shopify 2.0 even easier
                    with on-site messaging built in. Just go to your theme
                    editor in Shopify 2.0 to add iwocaPay banners advertising
                    pay later to your site in just a few clicks.
                  </div>
                </AlertBox>
                Build your business with Shopify - the global commerce platform
                - to sell online, offline, and everywhere in between.
              </>
            ) : (
              <>
                The global commerce platform. Build your business with Shopify
                to sell online, offline, and everywhere in between.
              </>
            )}
          </p>
          <h3 className={bodyStyles.subHeading}>
            When you connect iwocaPay to Shopify it'll automatically:
          </h3>
          <ul className={bodyStyles.bulletList}>
            <li>add iwocaPay as a payment method on your Shopify checkout</li>
            <li>
              reconcile all iwocaPay payments with your Shopify store data.
            </li>
          </ul>
          <div>
            <a
              href={
                'https://support.iwoca.co.uk/en/articles/8390053-integrating-iwocapay-with-shopify'
              }
              target="_blank"
              rel="noreferrer"
              className={bodyStyles.faqLink}
            >
              Shopify FAQs
              <img
                src={externalLinkSVG}
                alt="External Link Icon"
                className={bodyStyles.externalLinkIcon}
              />
            </a>
          </div>
        </div>
      </div>
      <div className={bodyStyles.footer}>
        {isConnected ? (
          <div className={styles.isConnectedFooter}>
            <img src={tickSVG} alt="Tick Icon" className={bodyStyles.tickSVG} />
            Connected
          </div>
        ) : (
          <Button
            variant={isConnected ? 'secondary-alt' : 'primary'}
            onClick={handleButtonClick}
            className={styles.connectButton}
          >
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
}
