import { useState, useReducer, useRef } from 'react';

import { fetchPostRepaymentRegisterRepaymentMethod } from '@iwoca/lapi-client/edge';
import { Label } from '@iwoca/orion';
import { Form, Formik } from 'formik';
import { Frames, CardNumber, ExpiryDate, Cvv, CardType } from 'frames-react';

import { Auth3DSecureModal } from './Auth3DSecureModal/Auth3DSecureModal';
import { CheckoutFormValues } from './CheckoutAddPaymentMethodForm.types';
import { pollCardLinking } from './pollCardLinking';
import { validateCheckoutAddCardForm } from './validateFormFields';
import { useGetFundingRequirement } from '../../../../../api/lending/lapiHooks';
import { Input } from '../../../../../components/Input/Input';
import { InputError } from '../../../../../components/InputError/InputError';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner/LoadingSpinner';
import { useStateKey } from '../../../../../hooks/useStateKey.hook';
import { getRuntimeEnvironment } from '../../../../../utils/getRuntimeEnvironment';
import logger from '../../../../../utils/logger';
import { SubmitButton } from '../../../../components/Button/Button';
import { useNavigateToNextRequirement } from '../../../hooks/useNavigateToNextRequirement';

const CHECKOUT_COM_PRODUCTION_KEY = 'pk_m6yjih7mvailyyn4fmgunatoiao';
const CHECKOUT_COM_STAGING_KEY = 'pk_sbox_y67mbbuym66rphki3ktkvbjugmt';

export const CheckoutComAddPaymentMethodForm = () => {
  const publicKey =
    getRuntimeEnvironment() === 'production'
      ? CHECKOUT_COM_PRODUCTION_KEY
      : CHECKOUT_COM_STAGING_KEY;

  const { goToNextRequirement } = useNavigateToNextRequirement();
  const forceUpdate = useReducer((x) => x + 1, 0)[1];
  const { fetchFundingRequirements } = useGetFundingRequirement({
    queryOptions: {
      enabled: false,
    },
  });
  const { stateKey } = useStateKey();
  const [submitError, setSubmitError] = useState<string | null>();
  const [postcode, setPostcode] = useState('');
  const [auth3DSRedirectUrl, setAuth3DSRedirectUrl] = useState<string | null>();
  const [cardTypeError, setCardTypeError] = useState(false);
  const confirmAuth3DSecureCompleteRef = useRef<() => void>();

  const handleAuth3DSecureRequired = (url: string): Promise<void> => {
    return new Promise((resolve) => {
      setAuth3DSRedirectUrl(url);
      confirmAuth3DSecureCompleteRef.current = resolve;
    });
  };

  const handleAuth3DSecureComplete = () => {
    confirmAuth3DSecureCompleteRef.current!();
    setAuth3DSRedirectUrl(null);
  };

  const handleSubmitAddCheckoutCard = async () => {
    setCardTypeError(false);

    if (!stateKey) {
      throw new Error('No statekey provided');
    }

    // 1. Submit card element to Checkout.com
    const { token, card_type } = await Frames.submitCard();

    // 2. Register tokenised card in the backend through an authentication payment
    try {
      const response = await fetchPostRepaymentRegisterRepaymentMethod({
        stateKey: stateKey,
        body: {
          data: {
            token,
            card_type: card_type as Uppercase<CardType>, // Type for card_type is wrong - thanks Checkout.com :(
          },
        },
      });

      const redirect_url = response.data.checkout_3ds_redirect_link;
      if (redirect_url) {
        await handleAuth3DSecureRequired(redirect_url);
      }
    } catch (error) {
      setCardTypeError(true);
      Frames.init(); // This resets <Frames /> so a new card can be resubmitted
    }

    // 3. Poll to see if the payment method has been registered
    // poll the card linking as it takes an undetermined amount of time
    // for the Checkout.com webhook to be registered
    const cardLinked = await pollCardLinking(stateKey);
    if (!cardLinked) {
      logger.error('Cant find primary payment card');
      throw new Error('An unknown error has occurred.');
    }
  };

  const initialValues: CheckoutFormValues = {
    billingPostcode: '',
    frames: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => validateCheckoutAddCardForm(values)}
      onSubmit={async () => {
        setSubmitError(null);

        try {
          await handleSubmitAddCheckoutCard();

          await fetchFundingRequirements();
          forceUpdate();
          goToNextRequirement();
        } catch (error: unknown) {
          const err = error as Error;
          setSubmitError(err.message);
        }
      }}
    >
      {({
        errors,
        touched,
        isValid,
        isValidating,
        dirty,
        isSubmitting,
        validateForm,
      }) => (
        <Form>
          {cardTypeError && (
            <p className="text-error">
              Credit cards are not supported for automatic repayments. Please
              use a debit card.
            </p>
          )}

          <Frames
            config={{
              publicKey: publicKey,
              cardholder: { billingAddress: { zip: postcode } },
              localization: {
                cardNumberPlaceholder: '1234 1234 1234 1234',
                expiryMonthPlaceholder: 'MM',
                expiryYearPlaceholder: 'YY',
                cvvPlaceholder: 'CVC',
              },
              style: {
                base: {
                  height: '43px',
                  borderRadius: '10px',
                  border: '1px solid rgba(204, 204, 204, 1)',
                  fontWeight: '400',
                  fontFamily: 'Aesop, sans-serif',
                  fontSize: '14px',
                  padding: '0.5rem',
                  width: 'calc(100% - 2px)',
                },
                focus: {
                  borderColor: 'rgb(105, 159, 227)',
                  borderWidth: '3px',
                },
                invalid: {
                  color: 'rgb(175 13 4)',
                },
                placeholder: {
                  base: {
                    color: '#8e8e8e',
                  },
                },
              },
            }}
            cardValidationChanged={async () => {
              await validateForm();
            }}
          >
            <div className="grid h-[100px] grid-cols-5 gap-l">
              <div className="col-span-3">
                <Label className="mb-m">Card number</Label>
                <CardNumber />
              </div>

              <div>
                <Label className="mb-m">Expiry</Label>
                <ExpiryDate />
              </div>

              <div>
                <Label className="mb-m">CVC</Label>
                <Cvv />
              </div>
            </div>
          </Frames>

          <div className="mb-2xl">
            <Input
              label="Billing postcode"
              name="billingPostcode"
              placeholder="e.g. N6 6NG"
              showError={false}
              onChange={(e) => {
                setPostcode(e.target.value);
              }}
            />
            <InputError
              isVisible={touched.billingPostcode || false}
              error={errors.billingPostcode}
            />
          </div>

          <SubmitButton
            disabled={isValidating || isSubmitting || !(isValid && dirty)}
          >
            {isSubmitting ? <LoadingSpinner /> : 'Add debit card now'}
          </SubmitButton>

          <InputError isVisible={!!errors.frames} error={errors.frames} />
          <InputError
            isVisible={submitError !== undefined}
            error={submitError || ''}
          />

          {auth3DSRedirectUrl && (
            <Auth3DSecureModal
              auth3DSRedirectUrl={auth3DSRedirectUrl}
              onAuth3DSecureComplete={handleAuth3DSecureComplete}
            />
          )}
        </Form>
      )}
    </Formik>
  );
};
