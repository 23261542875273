import { useContext } from 'react';

import { useLocation, useNavigate } from 'react-router';

import { useCurrentPayLink } from './useCurrentPayLink';
import { useCapturedPaymentDrawdown } from '../../Signup/CapturedPaymentDrawdownProvider';
import { useModal } from '../../store/ModalProvider';
import { buildQueryString } from '../../utils/queryParams';
import { DRAW_DOWN_MODAL } from '../components/DrawDownModal/DrawDownModal';
import { RouteContext, useAvailableRoutes } from '../routes';

const NOOP = {
  nextRequirementName: null,
  goToNextRequirement: () => {},
};

export function useNavigateToNextRequirement() {
  const { routes: ROUTES_CONFIG, basePath } = useContext(RouteContext);
  const { openModal: openDrawDownModal } = useModal(DRAW_DOWN_MODAL);
  const { requestDecision: requestCapturedPaymentDecision } =
    useCapturedPaymentDrawdown();
  const queryString = buildQueryString();
  const location = useLocation();
  const navigate = useNavigate();
  const availableRoutes = useAvailableRoutes();
  const { payLink } = useCurrentPayLink();
  const sanitisedPath = sanitisePathname(location.pathname);

  function openCorrectDrawDownModal() {
    return payLink?.capture_delay_hours
      ? requestCapturedPaymentDecision()
      : openDrawDownModal();
  }

  const currentIndex = ROUTES_CONFIG.findIndex(({ path }) => {
    return sanitisedPath === `${basePath}${path}/`;
  });

  // Allow entrypoint to determine the next (initial) step
  // Useful for sending to the root of the checkout
  if (currentIndex < 0 && sanitisedPath !== `${basePath}/`) return NOOP;

  // Find all the potential next routes
  const nextRoutes = ROUTES_CONFIG.slice(currentIndex + 1);

  // Find the next route available in the next Routes and in the available routes
  for (const nextRoute of nextRoutes) {
    const validRoute = availableRoutes.find(
      (availableRoute) => availableRoute.name === nextRoute.name,
    );

    if (!validRoute) continue;

    const nextValidRoute = `${basePath}${validRoute.path}/${queryString}`;

    return {
      nextRequirementName: validRoute.name,
      goToNextRequirement: () => navigate(nextValidRoute),
    };
  }

  return {
    nextRequirementName: 'Confirm plan and complete checkout',
    goToNextRequirement: openCorrectDrawDownModal,
  };
}

export function sanitisePathname(
  pathname: ReturnType<typeof useLocation>['pathname'],
) {
  const pathWithoutQuery = pathname.split('?')[0];
  const isTrailingSlash = pathWithoutQuery.slice(-1) === '/';

  if (isTrailingSlash) return pathWithoutQuery;

  return pathWithoutQuery + '/';
}
