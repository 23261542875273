import { fetchGetPaymentMethod } from '@iwoca/lapi-client/edge';

import { delay } from './delay';
import logger from '../../../../../utils/logger';

export async function pollCardLinking(stateKey: string) {
  for (let attempt = 1; attempt <= 7; attempt++) {
    try {
      const cardLinked = await validateCardLinked(stateKey);
      if (cardLinked) return true;

      await delay(1000);
    } catch (error) {
      logger.error('Failed to get payment method', {
        lapiError: error,
      });

      throw new Error('An unknown error has occurred.');
    }
  }

  return false;
}

async function validateCardLinked(stateKey: string) {
  const getPaymentMethodResponse = await fetchGetPaymentMethod({
    stateKey,
  }).catch((error) => {
    throw error;
  });

  if (
    !getPaymentMethodResponse.data.some((paymentMethod) => {
      return (
        paymentMethod.payment_method_type === 'card' &&
        paymentMethod.roles.repayment === 'primary'
      );
    })
  ) {
    return false;
  }

  return true;
}
