import { fetchPostRepaymentPaymentMethod } from '@iwoca/lapi-client/edge';
import {
  SetupIntent,
  Stripe,
  StripeCardNumberElement,
} from '@stripe/stripe-js';

import { pollCardLinking } from './pollCardLinking';
import logger from '../../../../../utils/logger';

// Stripe documentation for setting up future payments and 3D Secure:
// https://stripe.com/docs/payments/save-and-reuse?platform=web&html-or-react=react
// https://stripe.com/docs/payments/3d-secure#manual-three-ds

export const submitForm = async ({
  stateKey,
  billingPostcode,
  stripe,
  cardNumberElement,
  onStripe3DSecureRequired,
}: {
  stateKey: string;
  billingPostcode: string;
  stripe: Stripe;
  cardNumberElement: StripeCardNumberElement;
  onStripe3DSecureRequired: (url: string) => Promise<void>;
}) => {
  // 1. Create a payment intent.
  // https://stripe.com/docs/payments/save-and-reuse?platform=web&html-or-react=react#web-create-intent
  const postRepaymentMethodResponse = await fetchPostRepaymentPaymentMethod({
    stateKey,
    body: {
      data: {
        payment_method_type: 'card',
        role: 'primary',
      },
    },
  }).catch((error) => {
    logger.error('Failed to create repayment method', {
      lapiError: error,
    });

    throw new Error('An unknown error has occurred.');
  });

  // 2. Create a payment method with the customer's card details.
  const createPaymentMethodResponse = await createStripePaymentMethod(stripe, {
    cardNumberElement,
    billingPostcode,
  });

  // 3. Ensure the payment method is one that we accept.
  const allowedTypes = postRepaymentMethodResponse.data.accepted_card_types!;
  const cardType = createPaymentMethodResponse.paymentMethod.card!.funding;

  // skip check in stage for shopify review
  if (!window.location.hostname.includes('stage')) {
    if (!allowedTypes.includes(cardType)) {
      throw new Error('Please make sure you link a debit card');
    }
  }

  const clientSecret = postRepaymentMethodResponse.data.secret;

  // 4. Confirm the card setup with Stripe.
  const confirmCardSetupResponse = await confirmStripeCardSetupIntent(stripe, {
    clientSecret: postRepaymentMethodResponse.data.secret,
    cardNumberElement,
    billingPostcode,
  });

  // 5. Check if extra authorisation is required with 3D Secure.
  // https://stripe.com/docs/payments/3d-secure#manual-three-ds
  const stripe3DSecureRedirectURL = get3DSecureRedirectURL(
    confirmCardSetupResponse.setupIntent,
  );
  if (stripe3DSecureRedirectURL) {
    await onStripe3DSecureRequired(stripe3DSecureRedirectURL);

    // 5.1 Check that the card setup succeeded.
    const retrieveSetupIntentResponse = await retrieveStripeCardSetupIntent(
      stripe,
      { clientSecret },
    );

    if (retrieveSetupIntentResponse.setupIntent.status !== 'succeeded') {
      throw new Error('An unknown error has occurred.');
    }
  }

  // poll the card linking as it takes an undetermined amount of time
  // for the Stripe webhook to be registered
  const cardLinked = await pollCardLinking(stateKey);
  if (!cardLinked) {
    logger.error('Cant find primary payment card');
    throw new Error('An unknown error has occurred.');
  }
};

function createStripePaymentMethod(
  stripe: Stripe,
  {
    cardNumberElement,
    billingPostcode,
  }: { cardNumberElement: StripeCardNumberElement; billingPostcode: string },
) {
  // https://stripe.com/docs/js/payment_methods/create_payment_method
  return stripe
    .createPaymentMethod({
      type: 'card',
      card: cardNumberElement,
      billing_details: {
        address: { postal_code: billingPostcode },
      },
    })
    .then((response) => {
      if (response.error) {
        const { error } = response;
        logger.error('Failed to create payment method with stripe', {
          stripeError: error,
        });

        if (error.type === 'card_error') {
          throw new Error(error.message);
        } else {
          throw new Error('An unknown error has occurred.');
        }
      }

      return response;
    });
}

function confirmStripeCardSetupIntent(
  stripe: Stripe,
  {
    clientSecret,
    cardNumberElement,
    billingPostcode,
  }: {
    clientSecret: string;
    cardNumberElement: StripeCardNumberElement;
    billingPostcode: string;
  },
) {
  // https://stripe.com/docs/js/setup_intents/confirm_card_setup
  return stripe
    .confirmCardSetup(
      clientSecret,
      {
        payment_method: {
          card: cardNumberElement,
          billing_details: {
            address: { postal_code: billingPostcode },
          },
        },
        return_url:
          'https://pandora.iwoca.com/prod/uk/stripe/authentication_finished.html',
      },
      // Setting handleActions to false so we can take care of 3D Secure ourselves.
      // https://stripe.com/docs/payments/3d-secure#confirm-payment-intent
      // https://stripe.com/docs/js/payment_intents/confirm_card_payment#stripe_confirm_card_payment-options-handleActions
      { handleActions: false },
    )
    .then((response) => {
      if (response.error) {
        const { error } = response;
        logger.error('Failed to confirm card setup with stripe', {
          stripeError: error,
        });

        if (error.type === 'card_error') {
          throw new Error(error.message);
        } else {
          throw new Error('An unknown error has occurred.');
        }
      }

      return response;
    });
}

function retrieveStripeCardSetupIntent(
  stripe: Stripe,
  { clientSecret }: { clientSecret: string },
) {
  // https://stripe.com/docs/js/setup_intents/retrieve_setup_intent
  return stripe.retrieveSetupIntent(clientSecret).then((response) => {
    if (response.error) {
      const { error } = response;
      logger.error('Failed to retrieve payment intent from stripe', {
        stripeError: error,
      });

      if (error.type === 'card_error') {
        throw new Error(error.message);
      } else {
        throw new Error('An unknown error has occurred.');
      }
    }

    return response;
  });
}

function get3DSecureRedirectURL(setupIntent: SetupIntent) {
  return setupIntent.next_action?.redirect_to_url?.url || null;
}
