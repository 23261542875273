import { useNavigate } from 'react-router';

import { buildQueryString } from '../Buyer/utils/queryParams';
import { ValidRoutePaths } from '../routing/utils';

export const useSafeNavigate = () => {
  const navigatePage = useNavigate();
  const queryString = buildQueryString();

  const navigate = ({
    to,
    keepParams = true,
    newParams,
  }: {
    to: ValidRoutePaths;
    keepParams?: boolean;
    newParams?: Record<string, string>;
  }) => {
    let newQueryString = '';
    if (newParams) {
      const newSearchParams = new URLSearchParams();
      Object.entries(newParams).forEach(([key, value]) => {
        newSearchParams.append(key, value);
        newQueryString = newSearchParams.toString();
      });

      if (queryString) {
        newQueryString = `&${newQueryString}`;
      } else {
        newQueryString = `?${newQueryString}`;
      }
    }

    navigatePage(`${to}${keepParams ? queryString : ''}${newQueryString}`);
  };

  return {
    navigate,
  };
};
