import { useContext } from 'react';

import {
  fetchGetXeroIwocapaySellerAccount,
  GetXeroConnectionsResponse,
} from '@iwoca/lapi-client/edge';
import { useQuery } from '@tanstack/react-query';

import { GetXeroThemesAPIResponse } from './InvoiceBrandingTheme/InvoiceBrandingTheme.types';
import { XeroAccount, XeroTheme } from './Xero.types';
import { ActivationContext } from '../../activation/Activation';
import { fetchGetXeroConnections } from '../../api/lending/edge';
import { LapiError } from '../../api/lending/LapiError';
import { useStateKey } from '../../hooks/useStateKey.hook';
import { lendingApiFetchJson } from '../../Pages/lendingApiFetch';

export function useXero() {
  const { isOnboarded, isLoading: isLoadingContext } =
    useContext(ActivationContext);
  const { stateKey } = useStateKey();

  const canFetchXero = !!isOnboarded && !!stateKey;

  const {
    data: xeroAccount,
    isLoading: loadingXeroAccount,
    error: errorXeroAccount,
    refetch: fetchXeroAccount,
  } = useQuery({
    queryKey: ['fetchGetXeroIwocapaySellerAccount'],
    queryFn: () => fetchGetXeroIwocapaySellerAccount({ stateKey: stateKey! }),
    enabled: canFetchXero,
  });

  const {
    data: xeroThemes,
    isLoading: loadingXeroThemes,
    refetch: fetchXeroThemes,
    error: errorXeroThemes,
  } = useQuery({
    queryKey: ['fetchGetXeroIwocapaySellerBrandingThemes'],
    queryFn: () => fetchGetXeroIwocapaySellerBrandingThemes(stateKey!),
    enabled: canFetchXero,
  });

  const {
    data: xeroConnections,
    isLoading: loadingXeroConnections,
    refetch: fetchXeroConnections,
    error: errorXeroConnections,
  } = useQuery({
    queryKey: ['fetchGetXeroConnectionsIwocapaySeller'],
    queryFn: () => fetchGetXeroConnections(stateKey!),
    enabled: canFetchXero,
  });

  const isLoading =
    isLoadingContext ||
    loadingXeroAccount ||
    loadingXeroThemes ||
    loadingXeroConnections;

  const organisationName = getLinkedOrganisationName(
    xeroConnections?.data.connections,
    // @ts-expect-error
    xeroAccount?.data.seller_account,
  );

  const refresh = async () => {
    if (!canFetchXero) return;

    await Promise.all([
      fetchXeroConnections(),
      fetchXeroAccount(),
      fetchXeroThemes(),
    ]);
  };

  const themeOptions =
    xeroThemes?.data.branding_themes?.map((theme) => ({
      label: theme.name,
      value: theme.branding_theme_id,
      // isFixed is used for the react-select component to lock in options
      // this should be treated more as "isActive"
      isFixed: isIwocapayApplied(theme),
    })) ?? [];
  const hasError =
    !!errorXeroAccount || !!errorXeroThemes || !!errorXeroConnections;

  return {
    refreshing: false,
    stateKey: stateKey,
    loading: isLoading,
    error: hasError
      ? {
          xeroAccount: errorXeroAccount,
          xeroThemes: errorXeroThemes,
          xeroConnections: errorXeroConnections,
        }
      : null,
    themeOptions: themeOptions,
    xeroAccount: xeroAccount?.data!.seller_account,
    xeroConnections: isOnboarded ? xeroConnections?.data.connections : [],
    organisationName: organisationName,
    refresh,
  };
}

function isIwocapayApplied(theme: XeroTheme): boolean {
  return (
    theme.payment_services.filter(
      (service) => service.payment_service_name === 'iwocaPay',
    ).length > 0
  );
}

function getLinkedOrganisationName(
  connections?: GetXeroConnectionsResponse['data']['connections'],
  account?: XeroAccount,
) {
  const connection = connections?.find(
    (connection) => connection.tenant_id === account?.tenant.tenant_id,
  );

  return connection?.tenant_name;
}

async function fetchGetXeroIwocapaySellerBrandingThemes(
  stateKey: string,
): Promise<GetXeroThemesAPIResponse | undefined> {
  const res = await lendingApiFetchJson(
    `/api/lending/edge/xero/iwocapay_seller/branding_themes/${stateKey}/`,
  );

  if (!res.ok) {
    throw new LapiError(res.status, await res.text());
  }

  return res.json();
}
