import React from 'react';

import { Link, LinkProps } from 'react-router';

import { buildQueryString } from '../../Buyer/utils/queryParams';

export const LinkWithQuery = ({
  children,
  to,
  newQueryParams = undefined,
  ...props
}: { newQueryParams?: Record<string, string> | null } & LinkProps &
  React.RefAttributes<HTMLAnchorElement>) => {
  const queryString = buildQueryString(newQueryParams || undefined);

  return (
    <Link to={to + queryString} {...props}>
      {children}
    </Link>
  );
};
