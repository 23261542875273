import { useGetSplitTests } from './SplitTest';

export const useSkipEcomLanding = () => {
  const splitTests = useGetSplitTests({ shouldRunTest: true });

  const isInTest =
    splitTests.find(
      ({ test_name }) => test_name === 'iwocapay_ecom_skip_landing_page',
    )?.branch_name === 'INCLUDE';

  return isInTest;
};
