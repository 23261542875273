import { Label } from '@iwoca/orion';
import { useQuery } from '@tanstack/react-query';
import classnames from 'classnames';
import { Form, Formik } from 'formik';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router';
import Select, { ActionMeta, components } from 'react-select';

import { useSelectXeroThemes } from './Account.hook';
import { fetchGetXeroAccountsIwocapaySeller } from '../../../api/lending/edge';
import { Button } from '../../../Buyer/components/Button/Button';
import { buildQueryString } from '../../../Buyer/utils/queryParams';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import { useStateKey } from '../../../hooks/useStateKey.hook';
import { useXero } from '../Xero.context';
import { contextLoadedSuccessfully } from '../Xero.helpers';
import { DropdownOption } from '../Xero.types';

export function Account() {
  const navigate = useNavigate();
  const { stateKey } = useStateKey();
  const sellerXeroContext = useXero();
  const {
    selectedThemeOptions,
    setSelectedThemeOptions,
    clearThemes,
    themeOptions,
    hasThemesSelectionChanged,
    saveSelectedThemes,
  } = useSelectXeroThemes();

  const themesAreClearable =
    selectedThemeOptions &&
    selectedThemeOptions.some((theme) => !theme.isFixed);

  const companyNameDisabledOption = contextLoadedSuccessfully(sellerXeroContext)
    ? {
        label: sellerXeroContext.organisationName,
        value: sellerXeroContext.organisationName,
      }
    : undefined;

  const selectedPaymentAccountId =
    sellerXeroContext?.xeroAccount?.payment_account?.payment_account_id;

  const { data: xeroPaymentAccounts, isFetching: loadingXeroPaymentAccounts } =
    useQuery({
      queryKey: ['fetchGetXeroAccountsIwocapaySeller'],
      queryFn: () => fetchGetXeroAccountsIwocapaySeller(stateKey!),
      enabled:
        !!stateKey &&
        !!selectedPaymentAccountId &&
        contextLoadedSuccessfully(sellerXeroContext),
    });

  const selectedPaymentAccount = xeroPaymentAccounts?.data.accounts.find(
    (account) => account.account_id === selectedPaymentAccountId,
  );

  const unlinkXero = async () => {
    if (
      contextLoadedSuccessfully(sellerXeroContext) &&
      sellerXeroContext.xeroAccount?.tenant
    ) {
      // eslint-disable-next-line iwoca/prefer-lapi-client
      const response = await fetch(
        `/api/lending/edge/xero/connections/iwocapay_seller/${sellerXeroContext.stateKey}/`,
        {
          method: 'DELETE',
          credentials: 'same-origin',
          headers: { 'x-csrftoken': Cookies.get('csrftoken') as string },
        },
      );

      if (response.ok) {
        const queryString = buildQueryString();
        navigate?.('/pay/pay-links/' + queryString);
      }
    }
  };

  const handleXeroThemeSelectChange = (
    currSelectedXeroThemes: DropdownOption[],
    { action }: ActionMeta<unknown>,
  ) => {
    if (action === 'clear') {
      clearThemes();
    } else {
      setSelectedThemeOptions(currSelectedXeroThemes);
    }
  };

  if (loadingXeroPaymentAccounts) {
    return null;
  }

  return (
    <Formik
      onSubmit={saveSelectedThemes}
      initialValues={{
        xeroAccountOwner: companyNameDisabledOption?.value,
        linkedPaymentAccount: selectedPaymentAccount?.account_id,
      }}
    >
      <Form>
        <div className="flex h-full flex-col items-center justify-center p-s">
          <div className="mx-auto my-s max-w-[497px] p-l text-primary-10">
            <h1 className="m-0 mb-xl mt-xl text-4xl">
              You're linked with Xero!
            </h1>
            <div className="mb-l text-l font-reg">
              You can update which invoice theme has a Pay with iwocaPay option
              within Xero or by re-linking your account.{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="font-bold text-primary-40 no-underline"
                href="https://support.iwoca.co.uk/en/articles/4670005-connecting-your-xero-account-to-iwocapay"
              >
                Find out more in our Xero guide.
              </a>
            </div>
            <div className="mx-0 my-xl">
              <Dropdown
                name="xeroAccountOwner"
                labelText="Xero account owner"
                className={classnames('mb-l mt-0')}
                placeholder="Loading..."
                disabled={true}
                value={companyNameDisabledOption?.value}
              >
                <option value={companyNameDisabledOption?.value}>
                  {companyNameDisabledOption?.label}
                </option>
              </Dropdown>
            </div>
            <div className="mx-0 my-xl">
              <Dropdown
                name="linkedPaymentAccount"
                labelText="Linked payment account"
                className={classnames('mb-l mt-0')}
                placeholder="Loading..."
                disabled={true}
                value={selectedPaymentAccount?.account_id}
              >
                <option value={selectedPaymentAccount?.account_id}>
                  {selectedPaymentAccount?.name}
                </option>
              </Dropdown>
            </div>
            <div className="mx-0 mb-3xl mt-xl">
              <Label htmlFor="xeroThemes" className="mb-m block">
                Linked invoice themes
              </Label>
              <Select
                id="xeroThemes"
                isMulti
                classNames={{
                  control: () =>
                    'mb-m mt-0 !rounded-s !text-m !font-reg h-[48px]',
                  multiValue: (state) =>
                    classnames('!rounded-s !bg-[#143b6b]', {
                      '!opacity-50': state.data.isFixed,
                    }),
                  multiValueLabel: (state) =>
                    classnames('!text-white', {
                      '!font-bold !pr-s': state.data.isFixed,
                    }),
                  multiValueRemove: (state) =>
                    state.data.isFixed ? '!hidden' : '!text-white',
                }}
                placeholder={
                  themeOptions !== undefined ? 'Select a theme' : 'Loading...'
                }
                value={selectedThemeOptions}
                onChange={(selectedOptions, values) =>
                  handleXeroThemeSelectChange(
                    selectedOptions as DropdownOption[],
                    values,
                  )
                }
                options={themeOptions}
                isClearable={themesAreClearable}
                backspaceRemovesValue={false}
                components={{
                  ClearIndicator: (props) => {
                    return (
                      <div title="Clear icon">
                        <components.ClearIndicator {...props} />
                      </div>
                    );
                  },
                }}
              />
              <a
                className="text-s font-bold text-primary-40 no-underline"
                href="https://central.xero.com/s/article/Add-edit-or-delete-custom-invoice-quote-templates-UK"
                target="_blank"
                rel="noopener noreferrer"
              >
                Add a new theme in Xero
              </a>
            </div>
            <div>
              <Button
                disabled={!hasThemesSelectionChanged}
                onClick={saveSelectedThemes}
                className="mb-l w-full"
              >
                Save changes
              </Button>
              <div className="flex justify-center">
                <button
                  onClick={unlinkXero}
                  className="cursor-pointer appearance-none bg-transparent text-s font-bold text-primary-40 no-underline"
                  type="button"
                >
                  I'd like to un-link Xero
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  );
}
