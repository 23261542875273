import { useNavigate } from 'react-router';

import { StepType } from './Steps';
import { buildQueryString } from '../../utils/queryParams';
import { SIGNUP_BASE_PATH } from '../routes';

export function useNavigateToStep(stepId: StepType) {
  const navigate = useNavigate();
  const queryString = buildQueryString();

  return () => navigate(`${SIGNUP_BASE_PATH}details/${stepId}/${queryString}`);
}
