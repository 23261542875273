import Cookies from 'js-cookie';

import { lendingApiFetchJson } from './lendingApiFetch';
import { CustomerStateCompanyType } from '../api/lending/edge';
import logger from '../utils/logger';

type ResidentialAddress = {
  uid: string;
  house_number: string;
  house_name: string;
  street_line_1: string;
  street_line_2: string;
  town: string;
  postcode: string;
  equifax_token: string;
};

type Email = {
  email: string;
  marketing_opt_in: { agreed: boolean };
  uid: string;
  type: string;
};

type PhoneNumber = {
  number: string;
  uid: string;
  type: string;
};

type Step1Person = {
  uid: string;
  roles: Array<string>;
  first_name: string;
  last_name: string;
  title: string;
  emails: Array<Email>;
  phones: Array<PhoneNumber>;
};

type Person = Step1Person & {
  date_of_birth: string;
  residential_addresses: Array<ResidentialAddress>;
};

type Company = {
  type: CustomerStateCompanyType;
  registered_company_name?: string;
  company_number?: string;
};

export type CustomerState = {
  application?: {
    people?: Array<Partial<Person>>;
    company?: Partial<Company>;
  };
  schema_version?: string;
  state_key?: string;
  version?: string;
  ui?: {
    iwocapay_paylink_id?: string;
  };
};

export async function getState(stateKey: string) {
  const response = await lendingApiFetchJson(
    `/api/lending/edge/state/${stateKey}/`,
  );
  return await response.json();
}

export async function putState(state: CustomerState, stateKey?: string) {
  if (stateKey === undefined) {
    stateKey = state.state_key;
  }
  return await lendingApiFetchJson(
    `/api/lending/edge/state/${stateKey}/`,
    {
      method: 'put',
      body: JSON.stringify({
        data: state,
      }),
    },
    { 'x-csrftoken': Cookies.get('csrftoken') as string },
  );
}

export async function createBuyerAccount(state: CustomerState) {
  const response = await lendingApiFetchJson('/api/lending/edge/state/', {
    method: 'post',
    body: JSON.stringify({ data: state }),
  });
  if (!response.ok) {
    logger.error('Failed to create buyer', {
      statusCode: response.status,
      stateKey: state?.state_key,
    });
    throw new Error(
      `POST to state API failed ${response.status} ${response.statusText}`,
    );
  }
  return await response.json();
}
