import { useEffect } from 'react';

import {
  fetchGetAccounts,
  fetchGetApplication,
  fetchGetAssociatedPeople,
  fetchGetContext,
  fetchGetContextByStateKey,
  fetchGetEcommerceOrder,
  fetchGetFormalOffers,
  fetchGetIwocapayBuyerPayLinkPackage,
  fetchGetIwocapayBuyerSpendingLimitRequest,
  fetchGetIwocapayPaymentLater,
  fetchGetIwocapayReferral,
  fetchGetIwocapayReferrals,
  fetchGetIwocapayReferrer,
  fetchGetIwocapaySellerOnboardingAnswers,
  fetchGetPayLink,
  fetchGetProductOffers,
  fetchGetProfile,
  fetchGetSelectPaymentProcessor,
  fetchGetSeller,
  fetchGetSellerEnabledProducts,
  fetchGetSellerHandle,
  fetchGetSellerIntegrationsRegisterInterest,
  fetchGetSellerOnboardingRequirements,
  fetchGetSingleImmediatePayment,
  fetchGetStateByStateKey,
  fetchPostFundSellerRequest,
  fetchPostIwocapaySellerEcommerceCredentials,
  fetchPostLoanDocumentsSigned,
  fetchPutSellerEnabledProducts,
  fetchPutState,
  GetProfileResponse,
  GetStateResponse,
  LapiError,
  PutSellerEnabledProductsRequestBody,
} from '@iwoca/lapi-client/edge';
import {
  fetchGetBuyerSpendingLimit,
  fetchGetSellerAccessUser,
  fetchGetSellerBranding,
  fetchGetSellerConnection,
  fetchGetSellerPayLinkPackage,
  fetchGetSellerWebhooks,
} from '@iwoca/lapi-client/iwocapay';
import {
  keepPreviousData,
  Query,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import Cookies from 'js-cookie';

import {
  fetchGetDrawDownRepaymentScheduleIwocapay,
  fetchGetFundingRequirements,
  fetchGetLoanDocumentsRequiredIwocapay,
} from './edge';
import { useCurrentOfferId } from '../../Buyer/Checkout/hooks/useCurrentOfferId';
import { useCurrentPayLink } from '../../Buyer/Checkout/hooks/useCurrentPayLink';
import { useQueryParam } from '../../hooks/useQueryParam';
import { useStateKey } from '../../hooks/useStateKey.hook';
import { lendingApiFetchJson } from '../../Pages/lendingApiFetch';
import { getExampleRepaymentScheduleOverview } from '../../utils/exampleRepaymentSchedule';
import { registerStateKey } from '../../utils/fullStory';
import { identify } from '../../utils/tracking';

interface QueryOptions {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSuccess?: (data: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onError?: (error: any) => void;

  refetchInterval?:
    | false
    | number
    | ((query: Query) => number | false | undefined);
  enabled?: boolean;
}

interface AuthContextType {
  stateKey: string | undefined | null;
  isLoading: boolean;
  isLoggedIn: boolean;
  isSeller: boolean;
  user_signup_finished: boolean | null;
  isOnSoleTraderPilot: boolean;
  groups: string[];
}

export function useGetProfile() {
  const { data, isPending, refetch, error } = useQuery({
    queryKey: ['fetchGetProfile'],
    queryFn: () => fetchGetProfile({}),
  });

  const profileData: GetProfileResponse['data'] = {
    impersonator_type: data?.data?.impersonator_type,
    user_id: data?.data?.user_id!,
    user_type: data?.data?.user_type || null,
    full_name: data?.data?.full_name || '',
    email_address: data?.data?.email_address,
  };

  return {
    profile: profileData,
    getProfile: refetch,
    loadingProfile: isPending,
    profileError: error,
  };
}

export function useGetContext() {
  const impersonationStateKey = useQueryParam('state_key');

  const getContextFunction = impersonationStateKey
    ? () => fetchGetContextByStateKey({ stateKey: impersonationStateKey })
    : () => fetchGetContext({});

  const { data, isPending, isError, refetch, error } = useQuery({
    queryKey: ['fetchGetContext'],
    queryFn: async () => {
      const data = await getContextFunction();
      const stateKey = data?.data?.state_key as AuthContextType['stateKey'];

      if (stateKey) {
        registerStateKey(stateKey);
        identify({
          stateKey: stateKey,
          isImpersonating: !!impersonationStateKey,
        });
      }

      return data;
    },
  });

  const contextData: AuthContextType = {
    stateKey: data?.data?.state_key as AuthContextType['stateKey'],
    isLoading: isPending,
    // @ts-expect-error
    isSeller: data?.data?.is_iwocapay_seller,
    isLoggedIn: !!data && !isError,
    user_signup_finished: data?.data?.user_signup_finished || null,
    isOnSoleTraderPilot: data?.data?.is_iwocapay_st_pilot || false,
    // @ts-expect-error
    groups: data?.data.groups || null,
  };

  return {
    context: { ...data?.data, ...contextData },
    getContext: refetch,
    loadingContext: isPending,
    contextError: error,
  };
}

export function useGetIwocapayReferral() {
  const { stateKey } = useStateKey();

  const { data } = useQuery({
    queryKey: ['fetchGetIwocapayReferral'],
    queryFn: () => fetchGetIwocapayReferral({ stateKey: stateKey! }),
    enabled: Boolean(stateKey),
  });

  return { iwocapayReferral: data?.data };
}

export function useGetIwocapayReferrals() {
  const { stateKey } = useStateKey();

  const { data } = useQuery({
    queryKey: ['fetchGetIwocapayReferrals'],
    queryFn: () => fetchGetIwocapayReferrals({ stateKey: stateKey! }),
    enabled: Boolean(stateKey),
  });

  return { iwocapayReferrals: data?.data };
}

export function useGetIwocapayReferrer({
  referralCode,
  ...queryOptions
}: {
  referralCode?: string | null;
} & QueryOptions) {
  const { data, error, isError, isSuccess } = useQuery({
    queryKey: ['fetchGetIwocapayReferrer'],
    queryFn: () => fetchGetIwocapayReferrer({ referralCode: referralCode! }),
    enabled: !!referralCode,
  });

  useEffect(() => {
    if (isSuccess) {
      queryOptions.onSuccess?.(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const errorMessage = isError ? JSON.parse(error!.message) : null;

  return { iwocapayReferrer: data?.data, iwocapayReferrerError: errorMessage };
}

export function useGetEcommerceOrder({ orderId }: { orderId?: string }) {
  const { data, isPending, error } = useQuery({
    queryKey: ['fetchGetEcommerceOrder'],
    queryFn: () => fetchGetEcommerceOrder({ orderId: orderId! }),
    enabled: !!orderId,
  });

  return {
    ecommerceOrder: data?.data,
    loadingEcommerceOrder: isPending,
    ecommerceOrderError: error,
  };
}

export function useGetPayLinkByPayLinkId({
  payLinkId,
}: {
  payLinkId?: string;
}) {
  const { data, isPending, error } = useQuery({
    queryKey: ['fetchGetPayLink'],
    queryFn: () => fetchGetPayLink({ payLinkId: payLinkId! }),
    enabled: Boolean(payLinkId),
  });

  return { payLink: data, loadingPayLink: isPending, payLinkError: error };
}

export function useGetSingleImmediatePayment({
  paymentId,
  ...queryOptions
}: { paymentId?: string | null } & QueryOptions) {
  const { data, isPending, error, isSuccess, isError } = useQuery({
    queryKey: ['fetchGetSingleImmediatePayment', paymentId],
    queryFn: () => {
      return fetchGetSingleImmediatePayment({ paymentId: paymentId! });
    },
    enabled: Boolean(paymentId),
    refetchInterval: (query) => {
      if (!queryOptions.refetchInterval) return false;
      if (typeof queryOptions.refetchInterval !== 'function')
        return queryOptions.refetchInterval;
      // @ts-expect-error Currently returntype of data is unknown, this needs fixing
      return queryOptions.refetchInterval?.(query) || false;
    },
  });

  useEffect(() => {
    if (isSuccess) {
      queryOptions.onSuccess?.(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      queryOptions.onError?.(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  return {
    singleImmediatePayment: data?.data,
    loadingSingleImmediatePayment: isPending,
    singleImmediatePaymentError: error,
  };
}

export function useGetExampleRepaymentSchedule({
  amount,
  representativeInterest,
  pricingPromotions,
  enabled = true,
  duration = 3,
}: {
  amount: string;
  representativeInterest: number;
  pricingPromotions: string[];
  duration?: 3 | 12;
} & QueryOptions) {
  const { data } = useQuery({
    queryKey: [
      'fetchGetExampleRepaymentSchedule',
      amount,
      duration,
      representativeInterest,
      pricingPromotions,
    ],
    queryFn: () =>
      getExampleRepaymentScheduleOverview(
        parseFloat(amount!),
        representativeInterest,
        pricingPromotions,
        duration,
      ),
    enabled: enabled,
  });

  return { exampleRepaymentSchedule: data };
}

export function useGetIwocapayBuyerPayLinkPackage({
  payLinkPackageId,
  enabled = true,
}: {
  payLinkPackageId?: string;
} & QueryOptions) {
  const { data, isLoading } = useQuery({
    queryKey: ['fetchGetIwocapayBuyerPayLinkPackage', payLinkPackageId],
    queryFn: () =>
      fetchGetIwocapayBuyerPayLinkPackage({
        payLinkPackageId: payLinkPackageId!,
      }),
    enabled: enabled,
  });

  return {
    iwocapayBuyerPayLinkPackage: data?.data || null,
    loading: isLoading,
  };
}

export function useGetIwocapayPaymentLater() {
  const { stateKey } = useStateKey();

  const { data, isPending } = useQuery({
    queryKey: ['fetchGetIwocapayPaymentLater'],
    queryFn: () => fetchGetIwocapayPaymentLater({ stateKey: stateKey! }),
  });

  return { buyerPayLinks: data?.data || null, loadingBuyerPayLinks: isPending };
}

export function useGetApplication(queryOptions?: QueryOptions) {
  const { stateKey } = useStateKey();
  const { data, isPending, refetch } = useQuery({
    queryKey: ['fetchGetApplication'],
    queryFn: () => fetchGetApplication({ stateKey: stateKey! }),
    enabled: Boolean(stateKey) && (queryOptions?.enabled ?? true),
  });

  return {
    application: data?.data || null,
    loadingApplication: isPending,
    fetchApplication: refetch,
  };
}

export function useGetStateByStateKey() {
  const { stateKey } = useStateKey();

  const { data, refetch, isPending, isError } = useQuery({
    queryKey: ['fetchGetState'],
    queryFn: () => fetchGetStateByStateKey({ stateKey: stateKey! }),
    enabled: Boolean(stateKey),
  });

  return {
    state: !isError ? data?.data : null,
    loadingState: isPending,
    refetchState: refetch,
  };
}

export function useGetAccounts(queryOptions?: QueryOptions) {
  const { stateKey } = useStateKey();

  const { data, isPending, refetch } = useQuery({
    queryKey: ['fetchGetAccounts'],
    queryFn: () => fetchGetAccounts({ stateKey: stateKey! }),
    enabled: Boolean(stateKey) && (queryOptions?.enabled ?? true),
  });

  return {
    accounts: data?.data || null,
    loadingAccounts: isPending,
    fetchAccounts: refetch,
  };
}

export function useGetFormalOffers(queryOptions?: QueryOptions) {
  const { stateKey } = useStateKey();
  const enabled = Boolean(stateKey) && (queryOptions?.enabled ?? true);

  const { data, isPending } = useQuery({
    queryKey: ['fetchGetFormalOffers'],
    queryFn: () => fetchGetFormalOffers({ stateKey: stateKey! }),
    enabled: enabled,
  });

  return {
    formalOffers: data?.data || null,
    loadingFormalOffers: isPending && Boolean(enabled),
  };
}

export function useGetFundingRequirement({
  queryOptions,
}: {
  queryOptions?: QueryOptions;
} = {}) {
  const { stateKey } = useStateKey();
  const { payLink } = useCurrentPayLink();

  const offerId = useCurrentOfferId();

  const query = {
    ...(payLink ? { payLinkId: payLink.id } : undefined),
  };

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['fetchGetFundingRequirements'],
    queryFn: () =>
      fetchGetFundingRequirements({
        stateKey: stateKey!,
        offerId: offerId!,
        query: query,
      }),
    refetchInterval: (query) => {
      if (!queryOptions?.refetchInterval) return false;
      if (typeof queryOptions?.refetchInterval !== 'function')
        return queryOptions.refetchInterval;
      // @ts-expect-error Currently returntype of data is unknown, this needs fixing
      return queryOptions.refetchInterval?.(query) || false;
    },
    enabled: Boolean(stateKey) && Boolean(query) && Boolean(offerId),
  });

  return {
    fundingRequirement: data?.data || null,
    loadingFundingRequirement: isLoading,
    fetchFundingRequirements: refetch,
  };
}

// Django types need fixing to include this
export type AssociatedPerson = {
  correspondence_address?: {
    house_name?: string;
    street_line_1?: string;
    postcode?: string;
    town?: string;
  };
  house_name?: string;
  postcode?: string;
  street_line_1?: string;
  town?: string;
  first_name?: string;
  last_name?: string;
  month_of_birth?: number;
  roles?: string[];
  year_of_birth?: number;
};

export type GetAssociatedPeopleResponse = {
  data?: {
    people?: AssociatedPerson[];
  };
};

export function useGetAssociatedPeople() {
  const { stateKey } = useStateKey();

  const {
    data,
    isPending,
  }: { data: GetAssociatedPeopleResponse | undefined; isPending: boolean } =
    useQuery({
      queryKey: ['fetchGetAssociatedPeople'],
      queryFn: () => fetchGetAssociatedPeople({ stateKey: stateKey! }),
      enabled: Boolean(stateKey),
      gcTime: 0,
    });

  return {
    associatedPeople: data?.data?.people || [],
    loadingPeople: isPending,
  };
}

export function useGetDrawDownRepaymentScheduleIwocapay(
  queryOptions?: QueryOptions,
) {
  const { stateKey } = useStateKey();
  const offerId = useCurrentOfferId();

  const payLinkId = useQueryParam('payLinkId');

  const { data, isPending, refetch } = useQuery({
    queryKey: ['fetchGetDrawDownRepaymentScheduleIwocapay', offerId],
    queryFn: () => {
      return fetchGetDrawDownRepaymentScheduleIwocapay({
        stateKey: stateKey!,
        offerId: offerId!,
        query: { payLinkId: payLinkId! },
      });
    },
    enabled:
      Boolean(stateKey) &&
      Boolean(payLinkId) &&
      Boolean(offerId) &&
      (queryOptions?.enabled ?? true),
  });

  return {
    drawDownRepaymentScheduleIwocapay: data?.data || null,
    loadingDrawDownRepaymentScheduleIwocapay: isPending,
    fetchDrawDownRepaymentScheduleIwocapay: refetch,
  };
}

export function useGetLoanDocumentsRequiredIwocapay() {
  const { stateKey } = useStateKey();
  const offerId = useCurrentOfferId();

  const payLinkId = useQueryParam('payLinkId');

  const { data, isPending, refetch } = useQuery({
    queryKey: ['fetchGetLoanDocumentsRequiredIwocapay'],
    queryFn: () =>
      fetchGetLoanDocumentsRequiredIwocapay({
        stateKey: stateKey!,
        offerId: offerId!,
        query: {
          payLinkId: payLinkId!,
        },
      }),
    enabled: Boolean(stateKey) && Boolean(offerId) && Boolean(payLinkId),
  });

  return {
    loanDocumentsRequiredIwocapay: data?.data || null,
    loadingLoanDocumentsRequiredIwocapay: isPending,
    fetchLoanDocumentsRequiredIwocapay: refetch,
  };
}

// This is to allow backwards compatibility
// TODO: Remove and expose status code in lapi client
export const SELLER_GET_ERROR = "we can't find that iwocapay.me link";

export function useGetSellerByHandle(
  sellerHandle?: string,
  queryOptions?: QueryOptions,
) {
  const { data, isPending, isError, refetch } = useQuery({
    queryKey: ['fetchGetSellerHandle'],
    queryFn: () => fetchGetSellerHandle({ sellerHandle: sellerHandle! }),
    enabled: Boolean(sellerHandle) && (queryOptions?.enabled ?? true),
  });

  return {
    seller: data,
    loadingSeller: isPending,
    sellerError: isError ? SELLER_GET_ERROR : undefined,
    fetchSeller: refetch,
  };
}

export function useGetSellerByStateKey(queryOptions?: QueryOptions) {
  const { stateKey } = useStateKey();
  const { data, isPending, refetch } = useQuery({
    queryKey: ['fetchGetSeller'],
    queryFn: () => fetchGetSeller({ stateKey: stateKey! }),
    enabled: Boolean(stateKey) && (queryOptions?.enabled ?? true),
  });

  return {
    seller: data,
    loadingSeller: isPending,
    fetchSeller: refetch,
  };
}

export function useGetSellerOnboardingRequirements(
  queryOptions?: QueryOptions,
) {
  const { stateKey } = useStateKey();
  const { data, isPending, refetch } = useQuery({
    queryKey: ['fetchGetSellerOnboardingRequirements'],
    queryFn: () =>
      fetchGetSellerOnboardingRequirements({ stateKey: stateKey! }),
    enabled: Boolean(stateKey) && (queryOptions?.enabled ?? true),
    retry: true,
  });

  return {
    sellerOnboardingRequirements: data,
    loadingSellerOnboardingRequirements: isPending,
    fetchSellerOnboardingRequirements: refetch,
  };
}

export function useGetSellerOnboardingAnswers(queryOptions?: QueryOptions) {
  const { stateKey } = useStateKey();
  const { data, isPending, refetch } = useQuery({
    queryKey: ['fetchGetSellerOnboardingAnswers'],
    queryFn: () =>
      fetchGetIwocapaySellerOnboardingAnswers({ stateKey: stateKey! }),
    enabled: Boolean(stateKey) && (queryOptions?.enabled ?? true),
  });

  return {
    sellerOnboardingAnswers: data?.data || null,
    loadingSellerOnboardingAnswers: isPending,
    fetchSellerOnboardingAnswers: refetch,
  };
}

export function usePutState() {
  const { stateKey } = useStateKey();
  const { refetchState } = useGetStateByStateKey();

  const { mutateAsync: putState } = useMutation({
    mutationFn: async (state: GetStateResponse | undefined) => {
      if (!stateKey) {
        throw new Error('No stateKey was provided.');
      }
      if (!state) {
        throw new Error('No state was provided.');
      }
      await fetchPutState({ stateKey, body: state });
      await refetchState();
    },
  });
  return { putState };
}

export function usePostFundSeller() {
  const { stateKey } = useStateKey();
  const { payLink } = useCurrentPayLink();

  const offerId = useCurrentOfferId();

  const {
    mutateAsync: postFundSeller,
    isPending,
    isError,
    isSuccess,
  } = useMutation({
    mutationFn: async () => {
      if (!stateKey) {
        throw new Error('No stateKey was provided.');
      }
      if (!payLink) {
        throw new Error('No paylink was provided.');
      }
      if (!offerId) {
        throw new Error('No offerId was provided.');
      }
      return await fetchPostFundSellerRequest({
        stateKey,
        offerId: offerId,
        payLinkId: payLink?.id,
      });
    },
  });

  return { postFundSeller, isLoading: isPending, isError, isSuccess };
}

export function useGetProductOffers(queryOptions?: QueryOptions) {
  const { stateKey } = useStateKey();

  const { data, isPending, refetch } = useQuery({
    queryKey: ['fetchGetProductOffers'],
    queryFn: () => fetchGetProductOffers({ stateKey: stateKey! }),
    enabled: Boolean(stateKey) && (queryOptions?.enabled ?? true),
  });

  return {
    productOffers: data?.data || null,
    loadingProductOffers: isPending,
    fetchProductOffers: refetch,
  };
}

export function useGetSellerConnection() {
  const { stateKey } = useStateKey();

  const { data, isPending, refetch } = useQuery({
    queryKey: ['fetchGetSellerConnection'],
    queryFn: () => fetchGetSellerConnection({ stateKey: stateKey! }),
    enabled: Boolean(stateKey),
  });

  return {
    iwocapaySellerConnection: data?.data || null,
    loadingIwocapaySellerConnection: isPending,
    fetchIwocapaySellerConnection: refetch,
  };
}

export function useGetIwocapayIntegrationInterest() {
  const { stateKey } = useStateKey();

  const { data, isPending, refetch } = useQuery({
    queryKey: ['fetchGetIwocapayIntegrationInterest'],
    queryFn: () =>
      fetchGetSellerIntegrationsRegisterInterest({ stateKey: stateKey! }),
    enabled: Boolean(stateKey),
  });

  return {
    iwocapayIntegrationInterest: data?.data || null,
    loadingIwocapayIntegrationInterest: isPending,
    fetchIwocapayIntegrationInterest: refetch,
  };
}

export function useGetBuyerSpendingLimitRequest() {
  const { stateKey } = useStateKey();
  const { data, isPending, error } = useQuery({
    queryKey: ['fetchGetIwocapayBuyerSpendingLimitRequest'],
    queryFn: () =>
      fetchGetIwocapayBuyerSpendingLimitRequest({ stateKey: stateKey! }),
    enabled: Boolean(stateKey),
  });

  return {
    spendingLimitRequest: data,
    loadingSpendingLimitRequest: isPending,
    spendingLimitRequestError: error,
  };
}

export function useGetSellerEnabledProducts() {
  const { stateKey } = useStateKey();

  const { data, isPending, refetch } = useQuery({
    queryKey: ['fetchGetSellerEnabledProducts'],
    queryFn: () => fetchGetSellerEnabledProducts({ stateKey: stateKey! }),
    enabled: Boolean(stateKey),
  });

  return {
    sellerEnabledProducts: data?.data || null,
    loadingSellerEnabledProducts: isPending,
    fetchSellerEnabledProducts: refetch,
  };
}

export function usePutSellerEnabledProducts() {
  const { stateKey } = useStateKey();
  const { fetchSellerEnabledProducts } = useGetSellerEnabledProducts();

  const { mutateAsync: putSellerEnabledProducts } = useMutation({
    mutationFn: async (
      sellerEnabledProducts: PutSellerEnabledProductsRequestBody,
    ) => {
      if (!stateKey) {
        throw new Error('No stateKey was provided.');
      }
      if (!sellerEnabledProducts) {
        throw new Error('No sellerEnabledProducts was provided.');
      }
      await fetchPutSellerEnabledProducts({
        stateKey,
        body: sellerEnabledProducts,
      });
      await fetchSellerEnabledProducts();
    },
  });
  return { putSellerEnabledProducts };
}

export const useGetSellerAccessUsers = () => {
  const { stateKey } = useStateKey();

  const { data, refetch, isPending } = useQuery({
    queryKey: ['fetchGetSellerAccessUser'],
    queryFn: () =>
      fetchGetSellerAccessUser({
        stateKey: stateKey!,
      }),
    placeholderData: keepPreviousData,
    enabled: Boolean(stateKey),
  });

  return {
    accessUsers: data?.data || [],
    isLoading: isPending,
    refetchAccessUsers: refetch,
  };
};

const fetchGetIwocapayEcommerceCredentials = async ({
  stateKey,
}: {
  stateKey: string;
}) => {
  const res = await lendingApiFetchJson(
    `/api/lending/edge/iwocapay/seller/${stateKey}/ecommerce_credentials/`,
  );

  if (!res.ok) {
    throw new LapiError(res.status, await res.text());
  }

  const data = (await res.json()) as Promise<{
    data: { seller_id: string; token: string };
  }>;

  return data;
};

export const useGetIwocapaySellerEcommerceCredentials = () => {
  const { stateKey } = useStateKey();

  const { data, refetch, isPending, error } = useQuery({
    queryKey: ['fetchGetIwocapaySellerEcommerceCredentials'],
    queryFn: () =>
      fetchGetIwocapayEcommerceCredentials({
        stateKey: stateKey!,
      }),
    placeholderData: keepPreviousData,
    enabled: Boolean(stateKey),
  });

  const errorMessage = error?.message ? JSON.parse(error?.message) : null;
  const parsedError = errorMessage?.errors?.[0]?.detail || null;

  return {
    ecommerceCredentials: data?.data || null,
    loadingEcommerceCredentials: isPending,
    refetchEcommerceCredentials: refetch,
    ecommerceCredentialsError: parsedError,
  };
};

export function usePostLoanDocumentsSigned({
  documentId,
}: {
  documentId?: string;
}) {
  const { stateKey } = useStateKey();
  const dateNow = new Date().toISOString().split('T', 1)[0];

  const {
    mutateAsync: postLoanDocumentsSigned,
    isPending,
    isError,
    isSuccess,
  } = useMutation({
    mutationFn: async () => {
      if (!stateKey) {
        throw new Error('No stateKey was provided.');
      }
      if (!documentId) {
        throw new Error('No documentId was provided.');
      }
      return await fetchPostLoanDocumentsSigned({
        stateKey: stateKey!,
        body: {
          data: {
            date_signed: dateNow,
            document_id: documentId,
          },
        },
      });
    },
  });

  return {
    signLoanDocument: postLoanDocumentsSigned,
    isLoading: isPending,
    isError,
    isSuccess,
  };
}

export function useGetSellerBranding() {
  const { stateKey } = useStateKey();

  const { data, isPending } = useQuery({
    queryKey: ['fetchGetSellerBranding'],
    queryFn: () => fetchGetSellerBranding({ stateKey: stateKey! }),
  });

  return {
    sellerBranding: data?.data,
    loadingSellerBranding: isPending,
  };
}

export function useGetBuyerSpendingLimit() {
  const { stateKey } = useStateKey();
  const { data, isPending, error } = useQuery({
    queryKey: ['fetchGetBuyerSpendingLimit'],
    queryFn: () => fetchGetBuyerSpendingLimit({ stateKey: stateKey! }),
    enabled: Boolean(stateKey),
  });

  return {
    spendingLimit: data,
    loadingSpendingLimit: isPending,
    spendingLimitError: error,
  };
}

export const useGetSellerWebhooks = () => {
  const { stateKey } = useStateKey();

  const { data, refetch, isPending } = useQuery({
    queryKey: ['fetchGetSellerWebhooks'],
    queryFn: () =>
      fetchGetSellerWebhooks({
        stateKey: stateKey!,
      }),
    placeholderData: keepPreviousData,
  });

  return {
    sellerWebhooks: data?.data || [],
    loadingSellerWebhooks: isPending,
    refetchSellerWebhooks: refetch,
  };
};

export const useGetSellerPackagePayLinks = () => {
  const { stateKey } = useStateKey();

  const { data, refetch, isPending, error } = useQuery({
    queryKey: ['sellerPackageLinks', stateKey],
    queryFn: () => fetchGetSellerPayLinkPackage({ stateKey: stateKey! }),
    enabled: Boolean(stateKey),
  });

  return {
    data,
    refetch,
    isPending,
    error,
  };
};

export function useGetSelectPaymentProcessor() {
  const { data, isPending } = useQuery({
    queryKey: ['fetchGetSelectPaymentProcessor'],
    queryFn: () => fetchGetSelectPaymentProcessor({}),
  });

  return {
    paymentProcessor: data?.data?.payment_processor,
    loadingSelectPaymentProcessor: isPending,
  };
}
