import { Fragment, useEffect, useState } from 'react';

import { Combobox } from '@headlessui/react';
import {
  fetchGetPostcodeLookup,
  GetPostcodeLookupResponse,
} from '@iwoca/lapi-client/edge';
import { Input as OrionInput } from '@iwoca/orion';
import cn from 'classnames';
import { useField } from 'formik';

import styles from './AddressInput.module.css';
import { InlineButton } from '../../Buyer/components/InlineButton/InlineButton';
import { Input } from '../Input/Input';
import { InputError } from '../InputError/InputError';

type TResidentialAddress = GetPostcodeLookupResponse['data'][number];
type TResidentialAddressFormValues = {
  buildingNumber: string;
  buildingName: string;
  streetName: string;
  town: string;
  postcode: string;
};

export const AddressInput = ({
  address,
  disabled,
}: {
  address: TResidentialAddressFormValues;
  disabled?: boolean;
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [addressOptions, setAddressOptions] = useState<TResidentialAddress[]>(
    [],
  );
  const [addressField, , { setValue: setAddressField }] = useField('address');
  const [isManualAddressField, , { setValue: setIsManualAddress }] =
    useField('address.manual');
  const [, { touched, error }] = useField('address.postcode');

  useEffect(() => {
    const postcodeSearch = setTimeout(async () => {
      if (searchQuery) {
        try {
          const response = await fetchGetPostcodeLookup({
            query: { postcode: searchQuery },
          });
          setAddressOptions(response.data);
        } catch {
          setAddressOptions([]);
        }
      } else {
        setAddressOptions([]);
      }
    }, 200);

    return () => {
      clearTimeout(postcodeSearch);
    };
  }, [searchQuery]);

  return (
    <div>
      <Combobox
        value={addressField.value}
        disabled={disabled}
        onChange={async (address: TResidentialAddress) => {
          await setAddressField({
            buildingNumber: address.house_number,
            streetName: address.street_line_1,
            town: address.town,
            postcode: address.postcode,
            buildingName: address.house_name,
            equifaxToken: address.ptc_abs_code,
          });
          await setIsManualAddress(false);
        }}
      >
        <div className={styles.postcodeLookup}>
          <div className={styles.lookupInput}>
            <Combobox.Input
              onChange={(event) => {
                setSearchQuery(event.target.value);
              }}
              as={Fragment}
            >
              <OrionInput
                name="address.postcode"
                placeholder="Search postcode..."
                className="fs-mask"
                disabled={disabled}
                iconLeft="zoomOutline"
                label="Home postcode"
              />
            </Combobox.Input>
          </div>
          <InputError
            error={error}
            isVisible={!isManualAddressField.value && touched}
          />
          {searchQuery && (
            <Combobox.Options className={cn(styles.options, 'fs-mask')}>
              {addressOptions.length > 0 ? (
                addressOptions.map((address, index) => {
                  const addressName = [
                    address.house_name,
                    address.house_number,
                    address.street_line_1,
                  ]
                    .filter(Boolean)
                    .join(', ');
                  return (
                    <Combobox.Option
                      className={({ active }) =>
                        cn(styles.option, {
                          [styles.active]: active,
                        })
                      }
                      key={index}
                      value={address}
                    >
                      {addressName}
                    </Combobox.Option>
                  );
                })
              ) : (
                <p className={styles.noResults}>No results</p>
              )}
            </Combobox.Options>
          )}
        </div>
      </Combobox>
      {!isManualAddressField.value &&
        (address.postcode ? (
          <>
            <div className={cn(styles.selectedAddressDetails, 'fs-mask')}>
              <div>
                {address.buildingName} {address.buildingNumber}{' '}
                {address.streetName}
              </div>
              <div>{address.town}</div>
              <div>{address.postcode}</div>
            </div>
            <InlineButton onClick={() => setIsManualAddress(true)}>
              Edit address
            </InlineButton>
          </>
        ) : (
          <InlineButton
            className={styles.manualButton}
            onClick={async () => {
              await setAddressField({
                ...addressField.value,
                equifaxToken: '',
              });
              await setIsManualAddress(!isManualAddressField.value);
            }}
          >
            Enter address manually
          </InlineButton>
        ))}

      {isManualAddressField.value && (
        <div className={styles.addressLimitWidth}>
          <Input
            label="Building number"
            name="address.buildingNumber"
            className={styles.addressManualInput}
          />
          <Input
            label="Street name"
            name="address.streetName"
            className={styles.addressManualInput}
          />
          <Input
            label="Town"
            name="address.town"
            className={styles.addressManualInput}
          />
          <Input label="Postcode" name="address.postcode" />
        </div>
      )}
    </div>
  );
};
