import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Outlet } from 'react-router';

import { SELLER_ROUTES, redirectRoutes } from './seller.routes';
import { normaliseContainerRoutes } from './utils';
import { ErrorBoundary } from '../App';
import { IwQueryClientProvider } from '../store/IwQueryClientProvider';
import { Toaster } from '../store/IwToast';

const AppProviders = () => {
  return (
    <IwQueryClientProvider>
      <ReactQueryDevtools initialIsOpen={false} />
      <Toaster />
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    </IwQueryClientProvider>
  );
};

export const routes = [
  {
    element: <AppProviders />,
    children: [
      {
        path: '/pay',
        children: [
          ...normaliseContainerRoutes(SELLER_ROUTES),
          ...redirectRoutes,
        ],
      },
    ],
  },
];
