import { Route, Routes } from 'react-router';

import { Signup } from './Signup';
import { BusinessDetails } from './steps/BusinessDetails/BusinessDetails';
import { BusinessType } from './steps/BusinessType/BusinessType';
import { DirectorInfo } from './steps/DirectorInfo/DirectorInfo';
import { SignupError } from './steps/SignupError/SignupError';
import { SpeakToDirector } from './steps/SpeakToDirector/SpeakToDirector';

export const SIGNUP_BASE_PATH = '/pay/signup/';

export const SignupRoutes = () => {
  return (
    <Signup>
      <Routes>
        <Route path="/speak-to-director" element={<SpeakToDirector />} />
        <Route path="/error" element={<SignupError />} />
        <Route path="/details/:stepname" element={<BusinessDetailsRoutes />} />
        <Route path="/*" element={<BusinessDetailsRoutes />} />
      </Routes>
    </Signup>
  );
};

const BusinessDetailsRoutes = () => {
  return (
    <>
      <BusinessType />
      <BusinessDetails />
      <DirectorInfo />
    </>
  );
};
