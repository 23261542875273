import { useCallback, useEffect, useState } from 'react';

import { GetXeroConnectionsResponse } from '@iwoca/lapi-client/edge';
import { useNavigate } from 'react-router';

import styles from './Authorisation.module.css';
import { ConnectError } from './Authorisation.types';
import { SelectConnection } from './SelectConnection/SelectConnection';
import {
  fetchGetXeroConnections,
  fetchPostConnectIwocaPaySeller,
  fetchPostXeroIwocaPaySellerAccount,
} from '../../../api/lending/edge';
import { buildQueryString } from '../../../Buyer/utils/queryParams';
import { IwocaXeroErrorGraphic } from '../../../components/svg/Graphics/IwocaXeroErrorGraphic';
import { useStateKey } from '../../../hooks/useStateKey.hook';
import { LoadingGraphic } from '../LoadingGraphic/LoadingGraphic';
import { useXero } from '../Xero.context';
import { contextLoadedSuccessfully } from '../Xero.helpers';
import sellerXeroStyles from '../Xero.module.css';

export const Authorisation = () => {
  const sellerXeroContext = useXero();
  const { stateKey } = useStateKey();
  const navigate = useNavigate();
  const [error, setError] = useState<ConnectError>();

  const postSellerAccountAndRedirect = useCallback(
    async (stateKey: string, tenantId: string) => {
      try {
        await fetchPostXeroIwocaPaySellerAccount(stateKey, tenantId);

        const queryString = buildQueryString();
        navigate('/pay/xero/payment-account/' + queryString, {
          state: { updateContext: true },
        });
      } catch {}
    },
    [navigate],
  );

  useEffect(() => {
    const createSellerAccount = async () => {
      if (!contextLoadedSuccessfully(sellerXeroContext)) {
        return;
      }

      if (!stateKey) {
        throw new Error('No statekey provided to authorise xero');
      }
      const authorised = await authoriseXero(stateKey);

      if (!authorised) {
        setError('AuthorisationFailed');
        return;
      }

      const connections = await fetchConnections(stateKey);
      if (!connections?.length) {
        setError('AuthorisationFailed');
        return;
      }

      if (connections.length > 1) {
        setError('MultipleConnectionsFound');
        return;
      }

      await postSellerAccountAndRedirect(stateKey, connections[0].tenant_id!);

      // This needs to be around as a fallback
      setError('AuthorisationFailed');
    };

    void createSellerAccount();
  }, [sellerXeroContext, postSellerAccountAndRedirect, stateKey]);

  return (
    <div className={sellerXeroStyles.sellerXero}>
      <div className={styles.content}>
        <div className={styles.contentInner}>
          {error === 'AuthorisationFailed' && (
            <>
              <div className={styles.textContent}>
                <h1>We weren’t able to authorise you, please try again</h1>
              </div>
              <div className={styles.loadingGraphicContainer}>
                <IwocaXeroErrorGraphic />
              </div>
            </>
          )}
          {error === 'MultipleConnectionsFound' && (
            <SelectConnection
              postSellerAccountAndRedirect={postSellerAccountAndRedirect}
            />
          )}
          {!error && (
            <>
              <div className={styles.textContent}>
                <h1>You’re being redirected from Xero!</h1>
              </div>
              <div className={styles.loadingGraphicContainer}>
                <LoadingGraphic />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

async function authoriseXero(stateKey: string) {
  const { code, state } = Object.fromEntries(
    new URLSearchParams(window.location.search),
  );

  try {
    await fetchPostConnectIwocaPaySeller({
      stateKey,
      code,
      state,
    });
    return true;
  } catch {
    return false;
  }
}

async function fetchConnections(
  stateKey: string,
): Promise<GetXeroConnectionsResponse['data']['connections']> {
  try {
    return (await fetchGetXeroConnections(stateKey)).data.connections;
  } catch (err) {
    return [];
  }
}
