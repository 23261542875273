import { MouseEventHandler, ReactNode } from 'react';

import { Link } from 'react-router';

import { buildQueryString } from '../../Buyer/utils/queryParams';
import { ValidRoutePaths } from '../../routing/utils';

export const SafeLink = ({
  to,
  keepParams = true,
  onClick = undefined,
  className = undefined,
  children,
}: {
  to: ValidRoutePaths;
  keepParams?: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement> | undefined;
  className?: string | undefined;
  children: ReactNode;
}) => {
  const queryString = buildQueryString();
  return (
    <Link
      to={`${to}${keepParams ? queryString : ''}`}
      onClick={onClick}
      className={className}
    >
      {children}
    </Link>
  );
};
