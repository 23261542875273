import { Fragment, useEffect, useState } from 'react';

import { Combobox } from '@headlessui/react';
import { tracking } from '@iwoca/frontend-tracking-library';
import {
  fetchGetCompanySearch,
  GetCompanySearchResponse,
} from '@iwoca/lapi-client/edge';
import { AlertBox, Icon, Label, Spinner } from '@iwoca/orion';
import cn from 'classnames';
import { useField } from 'formik';

import styles from './CompanyInput.module.css';
import { CustomerStateCompanyType } from '../../api/lending/edge';
import { InlineButton } from '../../Buyer/components/InlineButton/InlineButton';
import { normaliseDate } from '../../Buyer/Signup/stateNormalisation';
import { getFormattedDate } from '../../Buyer/Signup/steps/BusinessDetails/BusinessDetails';
import { iwocapayTelDisplay, iwocapayTelLink } from '../../constants.json';
import { formatDate } from '../Input/formatDate';
import { Input } from '../Input/Input';
import { InputError } from '../InputError/InputError';
import SearchIcon from '../svg/MagnifyingGlass.svg';

function useFieldValue(id: string) {
  const [{ value }, , { setValue }] = useField(id);

  return [value, setValue];
}

const MANUAL_TRADING_DATE_ID = 'company.manualTradingDate';
const MANUAL_INPUT_ID = 'company.manualInput';

export const CompanyInput = ({
  disabled,
  allowManual,
  customerType = 'buyer',
}: {
  disabled?: boolean;
  allowManual?: boolean;
  customerType?: 'buyer' | 'seller';
}) => {
  const [isManualTradingDateInput, setManualTradingDateInput] = useFieldValue(
    MANUAL_TRADING_DATE_ID,
  );
  const [isManualInput, setIsManualInput] = useFieldValue(MANUAL_INPUT_ID);
  const [companyType] = useFieldValue('companyType');
  const [companyName] = useFieldValue('companyName');
  const [tradingStartDate] = useFieldValue('tradingStartDate');
  const [companyNumber] = useFieldValue('companyNumber');

  const isSoleTrader = getIsSoleTrader(companyType);

  useEffect(() => {
    if (companyType) {
      tracking.ampli.companyTypeSelected({ company_type: companyType });
    }
  }, [companyType]);

  if (isSoleTrader) {
    return <SoleTraderInput customerType={customerType} />;
  }

  if (isManualInput) {
    return <ManualInput />;
  }

  return (
    <div>
      <Label
        className={styles.label}
        htmlFor="companyName"
        id="companyName__label"
      >
        Your company / partnership name
      </Label>
      <CompaniesSearchInput disabled={Boolean(disabled)} />
      {allowManual ? (
        <InlineButton
          className={styles.manualButton}
          onClick={() => setIsManualInput(true)}
        >
          Enter company details manually
        </InlineButton>
      ) : null}
      {!isManualTradingDateInput ? (
        companyName && (
          <div className={styles.selectedDetails}>
            <p className={cn(styles.details, 'fs-mask')}>
              {companyName} ({companyNumber})
            </p>
            {tradingStartDate ? (
              <p className={cn(styles.details, 'fs-mask')}>
                Trading start date: {getFormattedDate(tradingStartDate)}
              </p>
            ) : null}
            <InlineButton
              onClick={() =>
                setManualTradingDateInput(!isManualTradingDateInput)
              }
              type="button"
            >
              Change trading start date
            </InlineButton>
          </div>
        )
      ) : (
        <>
          <Input
            className={styles.signupInput}
            label="Company number"
            name="companyNumber"
            disabled
          />
          <Input
            type="date-input"
            className={styles.signupInput}
            label="Trading start date (DD/MM/YYYY)"
            labelDescriptionText="This is the date your business started trading. This may be different to your company incorporation date."
            placeholder="DD/MM/YYYY"
            name="tradingStartDate"
          />
        </>
      )}
    </div>
  );
};

const CompaniesSearchInput = ({ disabled }: { disabled: boolean }) => {
  const [
    { value: companyName },
    { error, touched },
    { setValue: setCompanyName },
  ] = useField('companyName');
  const [, setCompanyNumberValue] = useFieldValue('companyNumber');
  const [, setTradingStartDate] = useFieldValue('tradingStartDate');
  const [, setManualTradingDateInput] = useFieldValue(MANUAL_TRADING_DATE_ID);
  const [isManualInput] = useFieldValue(MANUAL_INPUT_ID);

  const [numberOfCallsLoading, setNumberOfCallsLoading] = useState(0);
  const [companyOptions, setCompanyOptions] = useState<
    GetCompanySearchResponse['data'][number][]
  >([]);
  const [searchQuery, setSearchQuery] = useState('');

  const handleOptionChange = (
    company: GetCompanySearchResponse['data'][number],
  ) => {
    if (!company.registration_date) {
      return setManualTradingDateInput(true);
    }
    setManualTradingDateInput(false);
  };

  useEffect(() => {
    const companySearch = setTimeout(async () => {
      setNumberOfCallsLoading((prevNumber) => prevNumber + 1);
      if (searchQuery) {
        try {
          const response = await fetchGetCompanySearch({
            query: { company_name: searchQuery },
          });
          setCompanyOptions(response.data);
        } catch {
          setCompanyOptions([]);
        }
      } else {
        setCompanyOptions([]);
      }
      setNumberOfCallsLoading((prevNumber) => prevNumber - 1);
    }, 200);

    return () => {
      clearTimeout(companySearch);
    };
  }, [searchQuery]);

  const renderOptions = () => {
    if (numberOfCallsLoading > 0) {
      return (
        <div className={styles.spinnerContainer}>
          <Spinner dataTestId="company-search-spinner" />
        </div>
      );
    }

    return (
      <>
        {companyOptions.length > 0 ? (
          companyOptions.map((company, index) => (
            <Combobox.Option
              className={({ active }) =>
                cn(styles.option, {
                  [styles.active]: active,
                })
              }
              key={index}
              value={company}
            >
              {company.name} | {company.number}
            </Combobox.Option>
          ))
        ) : (
          <p className={styles.noResults}>No results</p>
        )}
      </>
    );
  };

  return (
    <Combobox
      value={companyName}
      onChange={async (company: GetCompanySearchResponse['data'][number]) => {
        await setCompanyName(company.name);
        setCompanyNumberValue(company.number);
        setTradingStartDate(
          company.registration_date
            ? normaliseDate(company.registration_date)
            : undefined,
        );

        handleOptionChange(company);
      }}
    >
      <div className={styles.companiesSearch}>
        <img className={styles.searchIcon} src={SearchIcon} alt="" />
        <Combobox.Input
          onChange={(event) => {
            setSearchQuery(event.target.value);
          }}
          as={Fragment}
        >
          <input
            name="companyName"
            aria-labelledby="companyName__label"
            placeholder="Company name..."
            className={cn(styles.searchInput, 'fs-mask')}
            disabled={disabled}
          />
        </Combobox.Input>
        <InputError error={error} isVisible={!isManualInput && touched} />
        {searchQuery && (
          <Combobox.Options className={cn(styles.options, 'fs-mask')}>
            {renderOptions()}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};

const SoleTraderInput = ({
  customerType = 'buyer',
}: {
  customerType: 'buyer' | 'seller';
}) => {
  const [companyType] = useFieldValue('companyType');

  const displayCompanyType =
    companyType === 'ordinary_partnership'
      ? 'Ordinary Partnerships'
      : 'Sole Traders';

  if (customerType === 'seller') {
    return (
      <AlertBox className="flex gap-m text-secondary-40" variant="connect">
        <Icon icon="infoCircleOutline" className="text-2xl" />
        <div className="py-2xs text-s">
          <h1 className="m-0 text-s font-bold">
            Unfortunately we don't work with {displayCompanyType} at the moment
          </h1>
          Get in touch on{' '}
          <a className="text-primary-40" href={iwocapayTelLink}>
            {iwocapayTelDisplay}
          </a>{' '}
          if you aren't sure what this means for your business.
        </div>
      </AlertBox>
    );
  }

  return (
    <>
      <div>
        <Input
          name="tradingName"
          label="Trading name"
          placeholder="Enter trading name..."
          className={styles.soleTraderContainWidth}
        />
      </div>
      <div>
        <Input
          label="Trading start date (DD/MM/YYYY)"
          labelDescriptionText="This is the date your business started trading."
          placeholder="DD/MM/YYYY"
          name="tradingStartDate"
          formatter={formatDate}
          className={styles.soleTraderContainWidth}
        />
      </div>
    </>
  );
};

const ManualInput = () => {
  return (
    <div className={styles.manualInputContainer}>
      <Input label="Your company / partnership name" name="companyName" />
      <Input label="Company number" name="companyNumber" />
    </div>
  );
};

const SOLE_TRADER_TYPES: Array<CustomerStateCompanyType> = [
  'sole_trader',
  'ordinary_partnership',
  'other',
];
function getIsSoleTrader(companyType?: CustomerStateCompanyType) {
  if (!companyType) return false;

  return SOLE_TRADER_TYPES.includes(companyType);
}
