import { useLocation } from 'react-router';

export function usePathname() {
  const location = useLocation();

  if (location.pathname.substring(location.pathname.length - 1) === '/') {
    return location.pathname;
  }

  return `${location.pathname}/`;
}
