import { fetchPostIwocapaySellerEcommerceCredentials } from '@iwoca/lapi-client/edge';
import { Button, Icon, Input } from '@iwoca/orion';
import { FormProvider, useForm } from 'react-hook-form';

import styles from './CredentialsPage.module.css';
import { useGetIwocapaySellerEcommerceCredentials } from '../../../api/lending/lapiHooks';
import { HookFormDropDown } from '../../../components/Dropdown/Dropdown';
import { copy } from '../../../components/util/copy';
import { useStateKey } from '../../../hooks/useStateKey.hook';

type TFormValues = {
  integration_use: string;
};

export const CredentialsPage = () => {
  const { stateKey } = useStateKey();
  const {
    ecommerceCredentials,
    loadingEcommerceCredentials,
    refetchEcommerceCredentials,
  } = useGetIwocapaySellerEcommerceCredentials();
  const hasCredentials = ecommerceCredentials !== null;

  const requestCredentials = async (integration: string) => {
    const redirect_url =
      integration === 'quickfile'
        ? 'https://www.quickfile.co.uk/payments/iwoca'
        : '';
    await fetchPostIwocapaySellerEcommerceCredentials({
      stateKey: stateKey!,
      body: { data: { redirect_url: redirect_url } },
    });
    await refetchEcommerceCredentials();
  };

  const initialFormValues = {
    integration_use: '',
  };

  const validate = (values: TFormValues) => {
    const errors: { [k: string]: string } = {};

    if (values.integration_use === '') {
      errors.integration_use =
        'Please select the integration you use. If none of the options applies, please contact your account manager.';
    }
    if (Object.keys(errors).length > 0) {
      return {
        values: {},
        errors,
      };
    }

    return {
      values,
      errors: {},
    };
  };
  const GenerateCredentials = () => {
    const onSubmit = async (data: { integration_use: string }) => {
      await requestCredentials(data.integration_use);
    };

    const methods = useForm({
      defaultValues: initialFormValues,
      resolver: validate,
      mode: 'onChange',
    });

    const onHookFormSubmit = methods.handleSubmit(onSubmit);

    return (
      <FormProvider {...methods}>
        <form onSubmit={onHookFormSubmit} noValidate>
          <HookFormDropDown
            name="integration_use"
            labelText="Which integration do you use?"
            placeholder="Select an option"
          >
            <option value="quickfile">QuickFile</option>
            <option value="woocommerce">WooCommerce</option>
            <option value="magento_2">Magento 2</option>
          </HookFormDropDown>
          <Button type="submit" className={styles.submitButton}>
            Generate API credentials
          </Button>
        </form>
      </FormProvider>
    );
  };

  if (loadingEcommerceCredentials || !stateKey) return null;

  return (
    <div className={styles.card}>
      <div className={styles.cardContent}>
        <div className={styles.header}>
          <h2 className={styles.title}>Generate your API credentials</h2>
          <p className={styles.description}>
            Copy your API credentials and add them to your integration.
          </p>
        </div>
        {hasCredentials ? <Credentials /> : <GenerateCredentials />}
      </div>
    </div>
  );
};

const Credentials = () => {
  const { ecommerceCredentials } = useGetIwocapaySellerEcommerceCredentials();

  return (
    <>
      <CredentialsField
        label="Seller Id"
        value={ecommerceCredentials!.seller_id}
      />
      <CredentialsField label="Token" value={ecommerceCredentials!.token} />
    </>
  );
};

const CredentialsField = ({
  label,
  value,
}: {
  label: string;
  value: string;
}) => {
  return (
    <div className={styles.credentialField}>
      <Input
        wrapperClassName={styles.credentialsInput}
        label={label}
        value={value}
        disabled={true}
        className="fs-mask"
      />
      <Button
        variant="tertiary"
        iconOnly={true}
        onClick={() => copy(value)}
        aria-label={`Copy ${label}`}
        className="fs-mask"
      >
        <Icon icon="clipboard" />
      </Button>
    </div>
  );
};
