import { createContext, useContext, useState } from 'react';

import { fetchPostFundSellerRequest } from '@iwoca/lapi-client/edge';
import { useNavigate } from 'react-router';

import { CAPTURED_PAYMENT_MODAL_ID } from './components/CapturedPaymentDecisionModal/CapturedPaymentDrawdownModal';
import { useStateKey } from '../../hooks/useStateKey.hook';
import { useCurrentOfferId } from '../Checkout/hooks/useCurrentOfferId';
import { useCurrentPayLink } from '../Checkout/hooks/useCurrentPayLink';
import { useModal } from '../store/ModalProvider';
import { buildQueryString } from '../utils/queryParams';

export enum CapturedPaymentDrawdownStatus {
  IDLE = 'idle',
  SUBMITTING = 'submitting',
  ACKNOWLEDGED = 'acknowledged',
  APPROVED = 'approved',
  ERROR = 'error',
}

const CapturedPaymentDrawdownContext = createContext<{
  decisionState: CapturedPaymentDrawdownStatus;
  requestDecision: () => Promise<void>;
}>({
  decisionState: CapturedPaymentDrawdownStatus.IDLE,
  requestDecision: async () => {},
});

export const CapturedPaymentDrawdownProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { openModal, closeModal } = useModal(CAPTURED_PAYMENT_MODAL_ID);
  const [decisionState, setDecisionState] =
    useState<CapturedPaymentDrawdownStatus>(CapturedPaymentDrawdownStatus.IDLE);
  const navigate = useNavigate();
  const queryString = buildQueryString();
  const { stateKey } = useStateKey();
  const { payLink } = useCurrentPayLink();
  const offerId = useCurrentOfferId();

  const requestDecision = async () => {
    setDecisionState(CapturedPaymentDrawdownStatus.SUBMITTING);
    openModal();

    if (!stateKey) {
      throw new Error('No stateKey was provided.');
    }
    if (!payLink) {
      throw new Error('No paylink was provided.');
    }
    if (!offerId) {
      throw new Error('No offerId was provided.');
    }

    try {
      await fetchPostFundSellerRequest({
        stateKey,
        offerId,
        payLinkId: payLink?.id,
      });
      await handleApproval();
    } catch (err) {
      setDecisionState(CapturedPaymentDrawdownStatus.ERROR);
    }
  };

  const handleApproval = async () => {
    setDecisionState(CapturedPaymentDrawdownStatus.ACKNOWLEDGED);
    await wait(2000);
    setDecisionState(CapturedPaymentDrawdownStatus.APPROVED);
    await wait(1500);

    closeModal();
    navigate(`/pay/checkout/offer/complete${queryString}`);
  };

  return (
    <CapturedPaymentDrawdownContext.Provider
      value={{ requestDecision, decisionState }}
    >
      {children}
    </CapturedPaymentDrawdownContext.Provider>
  );
};

export function useCapturedPaymentDrawdown() {
  const { requestDecision, decisionState } = useContext(
    CapturedPaymentDrawdownContext,
  );

  return { requestDecision, decisionState };
}

function wait(time: number) {
  return new Promise((resolve) => setTimeout(() => resolve(true), time));
}
