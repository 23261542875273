import {
  fetchPostPayLinkApplication,
  GetPayLinkResponse,
} from '@iwoca/lapi-client/edge';

import {
  createBuyerAccount,
  CustomerState,
  getState,
  putState,
} from '../../../../Pages/stateApi';
import { createState } from '../../../utils/customerState';
import { TPricingOption } from '../../utils/PayLinkLanding.types';

export async function submitBuyerStepZeroNewCustomer(
  emailAddress: string,
  marketingOptIn: boolean,
  payLink: GetPayLinkResponse,
  paymentOption: TPricingOption,
) {
  const customerState = await createBuyerAccount(
    createState(emailAddress, marketingOptIn, payLink.id),
  );
  await fetchPostPayLinkApplication({
    payLinkId: payLink.id,
    body: {
      data: {
        requested_duration:
          paymentOption === 'payLater12' ? 'TWELVE_MONTHS' : 'THREE_MONTHS',
      },
    },
  });

  return customerState;
}

export async function submitBuyerStepZeroReturningCustomer(
  stateKey: string,
  payLink: GetPayLinkResponse,
  paymentOption: TPricingOption,
) {
  const { data }: { data: CustomerState } = await getState(stateKey!);

  const newUserState = {
    ...data,
    ui: { ...data.ui, iwocapay_paylink_id: payLink.id },
  };

  await putState(newUserState);
  await fetchPostPayLinkApplication({
    payLinkId: payLink.id,
    body: {
      data: {
        requested_duration:
          paymentOption === 'payLater12' ? 'TWELVE_MONTHS' : 'THREE_MONTHS',
      },
    },
  });
}
