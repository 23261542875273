import { useLocation } from 'react-router';

import {
  ProgressIndicator,
  SECTION_TO_TITLE_MAPPING,
} from '../../../components/ProgressIndicator/ProgressIndicator';
import {
  STEP_STATUS,
  TStepStatus,
  useStepsStatus,
} from '../steps/useStepStatus';

export const SignupProgressIndicator = () => {
  const stepsStatus = useStepsStatus();
  const injectedStepStatus = useInjectStep({
    pathname: '/signup/choose-product',
    steps: stepsStatus,
    // 'choose_product' is not an official step
    // @ts-expect-error
    step: { id: 'choose_product', status: 'active' },
  });
  const progressSections = getProgressFromStepsStatus(injectedStepStatus);

  return <ProgressIndicator progress={progressSections} />;
};

function useInjectStep({
  pathname,
  steps,
  step,
}: {
  pathname: string;
  steps: ReturnType<typeof useStepsStatus>;
  step: TStepStatus;
}): ReturnType<typeof useStepsStatus> {
  const location = useLocation();
  if (!location.pathname.includes(pathname)) return steps;
  steps[step.id] = {
    id: step.id,
    status: step.status,
  };

  return steps;
}

function getProgressFromStepsStatus(
  stepsStatus: ReturnType<typeof useStepsStatus>,
) {
  const progressMap = Object.entries(SECTION_TO_TITLE_MAPPING);
  const progress = [];
  for (const [section, title] of progressMap) {
    const steps = stepsStatus[section];

    if (!steps) continue;

    if (!Array.isArray(steps)) {
      progress.push({
        title: title,
        percentage: steps.status === STEP_STATUS.INCOMPLETE ? 0 : 100,
        isActive: steps.status === STEP_STATUS.ACTIVE,
      });
      continue;
    }
    // @ts-ignore
    const isActive = !!steps.find((step) => step.status === STEP_STATUS.ACTIVE);

    const maxSteps = steps.length || 10;
    const completedSteps =
      steps.filter(({ status }) => {
        // Fixing typescript quirk, them not being widened properly
        // @ts-expect-error
        if ([STEP_STATUS.COMPLETE, STEP_STATUS.ACTIVE].includes(status))
          return true;
        return false;
      }).length || 0;

    const percentage = (completedSteps / maxSteps) * 100;

    progress.push({ title, percentage, isActive });
  }

  return progress;
}
